.advantages-list{
  border-bottom: 1px solid var(--border-color);
  &__item{
    border-top: 1px solid var(--border-color);
  }

  &__icon-wrap{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    border-top: 3px solid var(--color-orange);
    margin-top: -1px;
  }

  &__title{
    font-style: normal;
    font-weight: bold;
    color: var(--primary-color);
  }

  &__description{
    font-style: normal;
    font-weight: normal;
    color: var(--primary-color);
    line-height: 160%;
  }
}

@include respond-up('large'){
  .advantages-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 126px;
    grid-row-gap: 100px;
    padding: 100px 0;

    &__icon-wrap {
    padding-bottom: 43px;
    }

    &__item{
      flex: 0 50%;
    }

    &__title{
      font-size: 35px;
      line-height: 135%;
    }

    &__description{
      max-width: 445px;
    }
  }
}

@include respond-up('medium'){
  .advantages-list{

    &__icon-wrap{
      width: 128px;
      height: 139px;
    }

    &__icon{
      .svg-icon{
        width: 65px;
        height: 65px;
        margin-top: 20px;
        margin-left: 32px;
      }
    }

    &__title{
     padding-bottom: 30px;
    }

    &__description{
      font-size: 16px;
    }
  }
}
@include respond-down('medium'){
  .advantages-list{
    padding: 60px 0;

    &__item{
      padding-bottom: 61px;
      &:last-child{
        padding-bottom: 0;
      }
    }

    &__icon-wrap{
      padding-bottom: 23px;
    }

    &__title{
      font-size: 30px;
      line-height: 120%;
    }

    &__description{
      max-width: 568px;
    }
  }
}

@include respond-down('small'){
  .advantages-list{
    &__item{
      padding-bottom: 41px;
      &:last-child{
        padding-bottom: 0;
      }
    }

    &__icon-wrap{
      width: 92px;
      height: 100px;
      padding-bottom: 10px;
    }

    &__icon{
      .svg-icon{
        width: 50px;
        height: 50px;
        margin-top: 15px;
        margin-left: 21px;
      }
    }

    &__title{
      font-size: 24px;
      line-height: 135%;
      padding-bottom: 15px;
    }

    &__description{
      font-size: 15px;
    }
  }
}