.service-steps {
  &__list {
    display: flex;
  }

  &__item {
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
    background: var(--background-gray);
    align-items: center;
    border-radius: 10px 10px 0 0;
  }

  &__index {
    position: relative;
    font-weight: bold;
    color: var(--color-orange);

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      background: var(--border-color);
      top: 0;
      bottom: 0;
    }
  }

  &__term {
    font-weight: 500;
    font-size: 11px;
    line-height: 15/11*1em;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: var(--color-brown-light);
  }

  &__content-wrapper {
    background: #FFFFFF;
    border-radius: 0 0 10px 10px;
  }

  &__title {
    font-weight: bold;
    color: var(--primary-color);
  }

  &__description {
    font-weight: normal;
    color: var(--color-another-gray);
  }
}

@include respond-up('large') {
  .service-steps {
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    &__item {

    }

    &__top-row {

    }

    &__index {
      font-size: 22px;
      line-height: 29/22*1em;
      padding: 8px 0;

      &:before {
        right: -17px;
      }
    }

    &__term {

    }

    &__content-wrapper {

    }

    &__title {
      font-size: 22px;
      line-height: 29/22*1em;
    }

    &__description {

    }
  }
}

@include respond-up('medium') {
  .service-steps {
    &__top-row {
      padding: 0 25px 0 21px;
    }

    &__content-wrapper {
      padding: 30px 48px 40px;
    }

    &__title {
      padding-bottom: 13px;
    }

    &__description {
      font-size: 16px;
      line-height: 26/16*1em;
    }
  }
}

@include respond('medium') {
  .service-steps {
    &__list {

    }

    &__item {

    }

    &__top-row {

    }

    &__index {
      font-size: 20px;
      line-height: 26/20*1em;
      padding: 9px 0 10px;

      &:before {
        right: -19px;
      }
    }

    &__term {

    }

    &__content-wrapper {

    }

    &__title {

    }

    &__description {

    }
  }
}

@include respond-down('medium') {
  .service-steps {
    &__list {
      flex-direction: column;
      gap: 20px;
    }

    &__item {

    }

    &__top-row {

    }

    &__index {
      font-size: 18px;
      line-height: 23/18*1em;
      padding: 11px 0;
    }

    &__term {

    }

    &__content-wrapper {
      padding: 20px 30px;
    }

    &__title {

    }

    &__description {

    }
  }
}

@include respond-down('small') {
  .service-steps {
    &__list {

    }

    &__item {

    }

    &__top-row {
      padding: 0 20px 0 21px;
    }

    &__index {
      &:before {
        right: -19px;
      }
    }

    &__term {

    }

    &__content-wrapper {

    }

    &__title {
      padding-bottom: 10px;
    }

    &__description {
      font-size: 15px;
      line-height: 24/15*1em;
    }
  }
}