.map-block {
  &__front {
    z-index: 200;
    position: relative;
  }

  &__info-wrapper {
  }

  &__info {
    position: absolute;
    left: 0;
    box-shadow: 0 15px 45px rgba(54, 44, 40, 0.2);
    border-radius: 10px;
    background-color: white;
  }

  &__title {
    font-size: 20px;
    line-height: 135%;
    color: var(--primary-color);
    padding-bottom: 20px;
    border-bottom: 1px solid #E9E9E9;
  }

  &__info-list {
  }

  &__info-item {
    display: flex;

    &:nth-child(n+2) {
      margin-top: 25px;
    }
  }

  &__info-icon {
    flex: 0 0 15px;
    margin-right: 9px;
    padding-top: 6px;
  }

  &__info-text {
    font-size: 15px;
    line-height: 160%;
    color: var(--color-gray);
    flex: 0 1 auto;
  }

  &__map {
    position: relative;
    z-index: 100;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &__picture {
  }
}

@include respond-up('large') {
  .map-block {
    &__map {
      height: 630px;
    }

    &__info-wrapper {
      position: relative;
    }

    &__info {
      top: 60px;
      padding: 30px;
      max-width: 383px;
    }

    &__info-list {
      padding: 30px 0 40px;
    }

    &__picture {
      width: 100%;
      height: 213px;
    }
  }
}

@include respond-up('medium') {
  .map-block {
  }
}

@include respond('medium') {
  .map-block {
    padding-top: 131px;

    &__map {
      height: 542px;
    }

    &__title {
      grid-area: title;
    }

    &__picture {
      grid-area: image;
      height: 200px;
    }

    &__info-list {
      grid-area: info;
      padding-top: 30px;
    }

    &__info {
      top: -131px;
      left: #{$spacer-medium}px;
      right: #{$spacer-medium}px;
      display: grid;
      padding: 30px;
      grid-auto-rows: auto auto;
      grid-template-columns: 1fr 304px;
      grid-gap: 0 20px;
      grid-template-areas:
              "title image"
              "info image";
    }
  }
}

@include respond-down('medium') {
  .map-block {

  }
}

@include respond-down('small') {
  .map-block {
    padding-top: calc((100vw - (22px * 2)) / (331/194));

    &__map {
      height: 592px;
    }

    &__info {
      padding: 30px 20px 20px;
      left: #{$spacer-small}px;
      right: #{$spacer-small}px;
      top: calc((100vw - (22px * 2)) / (331/194) * -1);
    }

    &__info-list {
      padding: 20px 0 30px;
    }
  }
}