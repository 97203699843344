.services{
  &__all{
    border-bottom: 1px solid var(--border-color-gray);
    display: flex;
    justify-content: center;
  }

  &__all-link{
    display: flex;
    align-items: center;
    color: var(--color-orange);
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 160%;

    svg{
      margin-left: 8px;
      path{
        stroke: var(--color-orange);
      }
    }
  }
}

@include respond-up('large'){
  .services{
    margin-bottom: 96px;
    &__tabs{
      padding-bottom: 38px;

    }
    &__all{
      padding: 29px 0 30px;
    }
  }
}

@include respond-down('medium'){
  .services{
    margin-bottom: 60px;
    &__tabs{
      padding-bottom: 30px;
      overflow-x: scroll;
        ::-webkit-scrollbar {
        width: 0;
      }
    }
    &__tab-link{
      white-space: nowrap;
    }
    &__all{
      padding: 29px 0 30px;
    }
  }
}

@include respond-down('small'){
  .services{
    margin-bottom: 40px;

    &__tabs{
      padding-bottom: 20px;
    }

    &__all{
      padding: 21px 0 22px;
    }
  }
}