.hamburger{
  position: relative;
  height: 12px;
  width: 20px;

  &__line{
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: white;
    transform-origin: center;
    transition: {
      duration: .2s;
      timing-function: ease-in-out;
    };

    &_top{
      top: 0;
      transition-property: top, transform;
    }

    &_middle{
      top: calc(50% - 1px);
      transition-property: opacity;
    }

    &_bottom{
      bottom: 0;
      transition-property: bottom, transform;
    }
  }

  &._open{
    .hamburger__line{
      &_top{
        top: calc(50% - 1px);
        transform: rotate(45deg);
      }

      &_middle{
        opacity: 0;
      }

      &_bottom{
        bottom: calc(50% - 1px);
        transform: rotate(-45deg);
      }
    }
  }
}