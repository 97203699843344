.reviews {
  position: relative;
  overflow: hidden;
  background-color: var(--primary-color);

  &__background-image {
    position: absolute;
    content: '';
  }

  &__container {
    display: flex;
  }

  &__info-wrapper {

  }

  &__label {
    font-weight: bold;
  }

  &__title {
    margin-bottom: 20px;
    color: #FFFFFF;
  }

  &__description {
    color: var(--color-another-opacity);
  }

  &__more-link {

  }

  &__slider-container {

  }

  &__slider-list {

  }

  &__slider-item {

  }

  &__slider-control {
    position: absolute;
    right: 252/1400*100%;
  }
}

@include respond-up('large') {
 .reviews {
   margin-bottom: 100px;

   &__container {
     padding: 100px 0 130px;
     justify-content: space-between;
   }

   &__title {
     font-size: 35px;
     line-height: 47/35*1em;
   }

   &__label {
     margin-bottom: 30px;
   }

   &__description {
     padding-bottom: 60px;
   }

   &__info-wrapper {
     flex: 0 0 40%;
     padding-right: 90px;
   }

   &__slider-container {
     padding-top: 66px;
     width: 690px;
   }

   &__slider-list {

   }

   &__slider-item {

   }

   &__background-image {
     right: -100px;
     bottom: 25px;
   }

   &__more-link {
      &_medium-down {
        display: none;
      }
   }
 }
}

@include respond-up('medium') {
  .reviews {
    &__description {
      font-size: 16px;
      line-height: 26/16*1em;
    }
  }
}

@include respond('medium') {
  .reviews {
    margin-bottom: 60px;

    &__container {
      .tns-outer {
        margin: 0 -#{$spacer-medium}px;
      }
    }

    &__slider-list {
      margin: 0 #{$spacer-medium}px;
    }

    &__title {
      font-size: 30px;
      line-height: 36/30*1em;
    }

    &__label {
      margin-bottom: 25px;
    }

    &__description {
      padding-bottom: 50px;
    }

    &__slider-container {
      margin-bottom: 30px;
    }

    &__background-image {
      right: -80%;
      top: 0;
    }
  }
}

@include respond-down('medium') {
  .reviews {
    &__container {
      flex-direction: column;
      padding: 60px 0;
    }

    &__more-link {
      &_medium-down {
        display: flex !important;
        justify-content: center;
      }

      &_desktop {
        display: none;
      }
    }

    &__slider-control {
      display: none;
    }
  }
}

@include respond-down('small') {
  .reviews {
    margin-bottom: 40px;

    &__container {
      .tns-outer {
        margin: 0 -#{$spacer-small}px;
      }
    }

    &__slider-list {
      margin: 0 #{$spacer-small}px;
    }

    &__title {
      font-size: 24px;
      line-height: 32/24*1em;
    }

    &__label {
      margin-bottom: 20px;
    }

    &__description {
      padding-bottom: 30px;
    }

    &__slider-container {
      margin-bottom: 22px;
    }
  }
}