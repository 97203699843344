.news-card {
  padding: 25px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "picture date" "heading heading" "presslogo presslogo";

  &__heading {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 135%;
    color: var(--primary-color);
    grid-area: heading;
    padding-bottom: 26px;
  }

  &__subheading {
    display: none;
  }

  &__picture {
    grid-area: picture;
    margin-bottom: 24px;
    height: 115px;
    width: 115px;
  }

  &__image {
    border-radius: 8px;
    height: 115px;
    width: 115px;
    object-fit: cover;
  }

  &__link {
    display: block;
    align-self: end;
  }

  &__date {
    grid-area: date;
    justify-self: end;
  }

  &__press-logo {
    grid-area: presslogo;
    display: flex;
    margin-top: 7px;
    align-self: end;
  }

  &__logo-image {
    height: 50px;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .news-card {
    &__heading {
      max-width: 367px;
    }
  }
}


@include respond('medium') {
  .news-card {
    &__heading {
      max-width: 313px;
    }
  }
}

@include respond-down('medium') {
  .news-card {
    &__press-logo {
      margin-top: 20px;
    }

    &__logo-image {
      height: 36px;
    }
  }
}

@include respond-down('small') {
  .news-card {
    padding: 20px;

    grid-column: 1 / 1;

    grid-template-columns: auto;
    grid-template-areas: "picture date" "heading heading" "presslogo";

    &:first-child {

      grid-template-rows: auto auto auto auto auto;
      grid-template-areas: "picture" "date" "heading" "subheading" "presslogo";

      .news-card {
        &__date {
          justify-self: start;
        }

        &__subheading {
          font-size: 15px;
        }

        &__picture {
          margin-bottom: 20px;
          height: 225px;
          width: 225px;
        }

        &__logo-image {
          height: 36px;
        }

        &__date {
          margin-bottom: 10px;
        }

        &__press-logo {
          margin-top: 15px;
        }
      }
    }

    &__press-logo {
      margin-top: 0;
    }

    &__heading {
      font-size: 18px;
    }
  }
}

//для первого элемегта в блоке news (он занимает 2 столбца и выглядит иначе)

.news {
  &__item {
    &:first-child {
      .news-card {
        grid-column: 1 / 3;
        grid-template-areas: "picture date" "picture heading" "picture subheading" "picture presslogo";

        &__heading {
          font-weight: bold;
          font-size: 22px;
          line-height: 130%;
          padding-bottom: 16px;
        }

        &__subheading {
          display: initial;
          font-weight: normal;
          font-size: 16px;
          line-height: 160%;
          color: var(--color-gray);
          grid-area: subheading;
        }

        &__picture {
          margin-right: 35px;
          margin-bottom: 0;
          width: 224px;
          height: 224px;
        }

        &__image {
          width: 224px;
          height: 224px;
        }

        &__press-logo {
          margin-top: 26px;
        }

        &__press {
          gap: 0;

          .news-card {
            &__picture {
              margin-right: 40px;
              margin-bottom: 0;
              width: 277px;
              height: 277px;
            }

            &__image {
              width: 277px;
              height: 277px;
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .news {
    &__item {
      &:first-child {
        .news-card {
          &__heading {
            font-size: 20px;
            padding-bottom: 6px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .news {
    &__item {
      grid-column: 1 / 2;

      &:first-child {
        .news-card {

          grid-template-rows: auto auto auto auto auto;
          grid-template-areas: "picture" "date" "heading" "subheading" "presslogo";

          &__press {
            .news-card {
              &__picture {
                margin-right: 0;
                margin-bottom: 20px;
                width: 225px;
                height: 225px;

                img{
                  width: 225px;
                  height: 225px;
                }
              }
            }
          }
        }
      }
    }
  }
}