.form-modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 78px 40px 232px;


  &__icon {
    margin-bottom: 36px;
    background: white;
    box-shadow: 0 25px 35px rgba(54, 44, 40, 0.1);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 87px;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .1s;
    };
  }

  &__title {
    margin-bottom: 15px;
    z-index: 5;
    text-align: center;
    max-width: 344px;
  }

  &__subtitle {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.35em;
    color: var(--color-gray);
    text-align: center;
    max-width: 344px;
  }
}