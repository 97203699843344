.content-header {
  position: relative;

  &__content {
    position: relative;
    z-index: 100;
    overflow: hidden;
  }

  &__gradient {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(270deg, #573F32 11.04%, rgba(87, 54, 50, 0.4) 108.89%);
    opacity: 0.12;
  }

  &__inner {
    position: relative;
  }

  &__image {
    position: absolute;
    opacity: 0.05;
  }

  &__breadcrumbs {
    &_offer {
      .breadcrumbs__item {
        .breadcrumbs__link {
          color: #8D8482;
        }

        span {
          &:hover {
            color: #FFFFFF;
          }
        }
      }
    }
  }
  &__title-wrap{
    overflow: hidden;
  }

  &__title {
    font-weight: bold;
    color: var(--primary-color);
    line-height: 120%;
    transition: all .6s;
    transform: translateY(100px);
    opacity: 0;

    &.animate{
      transform: translateY(0);
      opacity: 1;
    }

    &_offer {
      color: #FFFFFF;
    }
  }

  &__subtitle-wrap{
    overflow: hidden;
  }

  &__subtitle {
    line-height: 150%;
    color: var(--color-gray);
    transition: all .8s;
    transform: translateY(100px);
    opacity: 0;

    &.animate{
      transform: translateY(0);
      opacity: 1;
    }

    &_offer {
      color: var(--color-another-opacity);
    }
  }
}



@include respond-up('large') {
  .content-header {
    &__inner {
      padding-top: 50px;
    }

    &__title {
      font-size: 50px;
      padding: 33px 0 25px;
      max-width: 690px;

      &_offer {
        padding: 75px 0 25px;
      }
    }

    &__subtitle {
      font-size: 20px;
      padding-bottom: 60px;
      max-width: 690px;
    }

    &__image {
      left: 706px;
    }

    &__tabs {
      padding-top: 47px;
    }
  }
}

@include respond-up('medium') {
  .content-header {

    &__subtitle {
      line-height: 160%;
    }


    &__image {
      top: 27px;
    }
  }
}

@include respond('medium') {
  .content-header {
    &__inner {
      padding-top: 40px;
    }

    &__title {
      font-size: 45px;
      padding: 30px 0 15px;
    }

    &__subtitle {
      font-size: 18px;
    }

    &__image {
      left: 381px;
    }
  }
}

@include respond-down('medium') {
  .content-header {

    &__subtitle {
      padding-bottom: 40px;
      max-width: 569px;
    }
  }
}

@include respond-down('small') {
  .content-header {
    &__inner {
      padding-top: 30px;
    }

    &__title {
      font-size: 30px;
      padding: 30px 0 15px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 160%;
    }

    &__image {
      display: none;
    }
  }
}