.text {
  font-style: normal;
  font-weight: normal;
  font-size: var(--body-font-size);
  line-height: 160%;
  color: var(--primary-color);

  &.important-text {
    font-weight: bold;
    line-height: 150%;
  }
}

.text-grey {
  color: var(--color-gray);
}

.text-block{
  h4{
    margin-bottom: 25px;
  }
}

/*a{
  color: var(--color-orange);

  &:hover{
    color: var(--color-orange-hover);
  }
}*/

.file-link {
  color: var(--color-orange);
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  display: flex;

  svg {
    margin-right: 5px;
  }
}

.text-label {
  background: var(--color-orange-opacity);
  border-radius: 2px;
  padding: 2px 8px;
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--color-orange);
}

.date {
  display: inline-block;
  font-size: 20px;
  font-weight: 300;
  color: var(--color-orange);
  letter-spacing: -0.05em;

  &__day {
    font-size: 38px;
  }

  &__month {
    letter-spacing: 0;
  }

  &_announcement {
    .date {
      font-size: 16px;

      &__day {
        font-size: 31px;
      }
    }
  }
}

@include respond-down('medium') {
  .file-link {
    a {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .date {
    &__day {
      font-size: 20px;
    }
  }
}