.partner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__logo {
    height: 64px;
    max-width: 140px;
  }

  &__logo-image{
    height: 100%;
    object-fit: contain;
  }

  &__description {
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    color: var(--color-gray);
  }

  &__link {
    display: inline-block;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0;
  }

  &__document-link{

    color: var(--color-orange);
    font-weight: 500;
    display: flex;

    svg {
      margin-right: 5px;
    }
  }
}

@include respond-up('large') {
  .partner {
    padding: 30px;
    height: 100%;

    &__wrap{
      flex-grow: 0;
    }

    &__logo {
      margin-bottom: 30px;
      filter: grayscale(1);
      transition: var(--default-transition);
    }

    &:hover{
      .partner {
        &__logo {
          filter: grayscale(0);
        }
      }
    }

    &__description {
      font-size: 16px;
      padding-bottom: 30px;
    }

    &__link {
      margin-bottom: 60px;
      font-size: 18px;
      color: var(--color-gray);
      transition: var(--default-transition);
      border-bottom: 1px solid #fff;

      &:hover{
        color: var(--color-orange);
        border-bottom: 1px solid var(--color-orange);
      }
    }

    &__document-link {
      font-size: 16px;
      line-height: 135%;
    }

    &__document {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
    }
  }
}

@include respond-down('medium') {
  .partner {
    padding: 25px;
    height: 100%;

    &__wrap{
      flex-grow: 1;
    }

    &__logo {
      padding-bottom: 30px;
    }

    &__description {
      font-size: 16px;
      padding-bottom: 20px;
    }

    &__link {
      margin-bottom: 40px;
      font-size: 18px;
      color: var(--color-orange);
      border-bottom: 1px solid var(--color-orange);
    }

    &__document-link {
      font-size: 16px;
      line-height: 135%;
    }

    &__document {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
    }
  }
}

@include respond-down('small') {
  .partner {
    padding: 20px;

    &__logo {
      padding-bottom: 30px;
    }

    &__description {
      font-size: 15px;
      padding-bottom: 20px;
    }

    &__link {
      margin-bottom: 36px;
      font-size: 16px;
      color: var(--color-orange);
      border-bottom: 1px solid var(--color-orange);
    }

    &__document-link {
      font-size: 15px;
      line-height: 20px;
    }

  }
}