.publications {
  background-color: var(--color-gray-light);

  &__top {
    display: flex;
  }

  &__smart-tabs-wrap {
    display: flex;
    border-bottom: 1px solid var(--border-color-gray);
    width: 100%;
  }

  &__smart-tabs-item{
    margin-bottom: -1px;
  }

  &__show-all-link-value {
    text-transform: lowercase;
  }

  &__show-all{
    display: flex;
    align-items: center;
    color: var(--color-orange);
    font-style: normal;
    font-weight: bold;
    line-height: 160%;
    font-size: 17px;

    svg{
      margin-left: 8px;
      path{
        stroke: var(--color-orange);
      }
    }
  }
}

@include respond-up('large') {
  .publications {
    padding-bottom: 100px;
    &__label{
      margin-bottom: 30px;
    }
    &__top {
      margin-bottom: 60px;
    }

    &__smart-tabs-wrap {
      padding-top: 8px;
    }

    &__title {
      margin-right: 58px;
      flex: 0 0 auto;
    }
  }
}

@include respond-up('medium') {
  .publications {
    &__top {
      align-items: center;
    }

    &__smart-tabs-wrap {
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 1 auto;
    }

    &__show-all {
      padding-top: 1px;

    }

    &__title{
      padding-bottom: 40px;
    }
  }
}

@include respond('medium') {
  .publications {
    padding-bottom: 60px;
    &__label{
      margin-bottom: 25px;
    }

    &__top {
      margin-bottom: 40px;
    }

    &__smart-tabs {
      margin-right: 80px;
    }

    &__smart-tabs-wrap {
      width: 100%;
    }
  }
}

@include respond-down('medium') {
  .publications {
    position: relative;

    &__wrapper{
      position: relative;
      border-bottom: 1px solid var(--border-color-gray);
    }

    &__top {
      flex-direction: column;
      align-items: flex-start;
    }

    &__show-all{
      position: absolute;
      width: 100%;
      bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@include respond-down('small') {
  .publications {
    padding-bottom: 78px;

    &__label{
      margin-bottom: 20px;
    }

    &__top {
      margin-bottom: 30px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__smart-tabs-wrap {

    }

    &__show-all {
    }
  }
}