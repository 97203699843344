.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;

  &__header {
    z-index: 1000;
  }

  &__footer {

  }
}