.contacts-page {
  &__info {
    display: flex;
  }

  &__contacts {
    display: flex;
  }

  &__info-item {

  }

  &__info-name {
    line-height: 160%;
    color: var(--color-gray);
  }

  &__info-value {
    display: block;
    font-size: 18px;
    line-height: 150%;
    color: var(--primary-color);
    transition: {
      property: color;
      duration: .3s;
    };

    &:hover {
      color: var(--color-orange);
    }
  }

  &__socials {
  }

  &__form {

  }
}

@include respond-up('large') {
  .contacts-page {

    &__info-item {
      &:nth-child(n+2) {
        padding-left: 94px;
        margin-left: 116px;
      }
    }
  }
}

@include respond-up('medium') {
  .contacts-page {
    &__info {
      justify-content: space-between;
      padding: 80px 0;
    }

    &__info-item {
      &:nth-child(n+2) {
        border-left: 1px solid rgba(0, 0, 0, 0.16);
      }
    }

    &__info-name {
      font-size: 15px;
      margin-bottom: 10px;
    }

    &__socials {
      align-items: center;
      display: flex;
    }
  }
}

@include respond('medium') {
  .contacts-page {
    &__info {
      padding: 80px 0;
    }

    &__info-item {
      &:nth-child(n+2) {
        padding-left: 50px;
        margin-left: 82px;
      }
    }
  }
}

@include respond-down('medium') {
  .contacts-page {

  }
}

@include respond-down('small') {
  .contacts-page {
    &__info {
      padding: 40px 0;
      flex-direction: column;
    }

    &__contacts {
      flex-direction: column;
      padding-bottom: 10px;
    }

    &__info-item {
      margin-bottom: 20px;
    }

    &__info-name {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }
}

