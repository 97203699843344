.social-sharing {
  padding: 30px 0;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 15px;
  }

  .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
    margin: 0 5px;
  }

  .ya-share2 {
    &__list {
      display: flex;
      margin: 0 -5px;

      .ya-share2__list_direction_horizontal {
        .ya-share2__item {
          margin: 0 5px;
        }
      }
    }


    &__container_shape_round {
      .ya-share2__badge {
        border: 1px solid var(--border-color);
        background: var(--t);
      }
    }

    &__container_size_m .ya-share2__badge .ya-share2__icon {
      height: 42px;
      width: 42px;
    }

    &__container_shape_round.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
      background-size: 32px 32px;
      filter: brightness(0.63);
    }
  }
}