/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 40;

$grid: (
  x-small: (
    from: 0,
    to: 374,
    spacer: $spacer-smaller
  ),
  small: (
    from: 375,
    to: 767,
    spacer: $spacer-small
  ),
  medium: (
    from: 768,
    to: 1279,
    spacer: $spacer-medium
  ),
  large: (
    from: 1280,
    to: 1920,
    spacer: $spacer-large
  ),
  x-large: (
    from: 1921,
    spacer: $spacer-large
  )
);

:root {
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Effra", "Arial", sans-serif;
  --h-font: "Effra", "Arial", sans-serif;

  --t: transparent;

  --primary-color: #392B2A;
  --background-color: #F6F1F0;
  --background-gray: #F9F6F6;
  --border-color: #DCDAD8;
  --border-color-gray: rgba(150, 146, 145, 0.5);
  --alert-color: #FF3D00;
  --gray-text: #8D8482;
  --modal-background-color: #F7F1F0;

  --color-light: #CDB199;
  --color-brown: #573632;
  --color-brown-light: #815749;
  --color-brown-another: #DCCFC7;
  --color-brown-dark: #29000A;
  --color-brown-opacity: rgba(87, 54, 50, 0.2);
  --color-orange: #FF9457;
  --color-orange-hover: #FF7426;
  --color-orange-opacity: rgba(255, 148, 87, 0.2);
  --color-orange-light: #F1D8CA;
  --color-gray: #969291;
  --color-another-gray: #8D8482;
  --color-brown-gray: #A49491;
  --color-gray-opacity: rgba(220, 218, 216, 0.4);
  --color-light-gray-opacity: rgba(0, 0, 0, 0.12);
  --color-another-opacity: rgba(255, 255, 255, 0.7);


  --default-input-height: 33px;

  --default-transition: all 0.4s;

  --medium-inner-width: 1279;
}