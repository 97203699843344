.recall {
  background: #FFFFFF;
  box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
  border-radius: 10px;

  &__top-wrapper {
    display: flex;
  }

  &__phone-schedule-wrapper {

  }

  &__phone {
    display: block;
    font-weight: bold;
    font-size: 22px;
    line-height: 29/22*1em;
    color: var(--primary-color);
    padding-bottom: 13px;
  }

  &__schedule {
    font-weight: normal;
    font-size: 15px;
    line-height: 24/15*1em;
    color: var(--color-another-gray);
  }

  &__text {
    font-weight: normal;
    font-style: italic;
    font-size: 20px;
    line-height: 34/20*1em;
    color: var(--primary-color);
  }

  &__link {
    display: block;
    padding: 13px 0;
    font-weight: bold;
    font-size: 17px;
    line-height: 27/17*1em;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--color-orange);
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--border-color);
    }
  }
}

@include respond-up('large') {
  .recall {
    &__top-wrapper {
      flex-direction: column;
      padding: 29px 35px 41px;
    }

    &__phone-schedule-wrapper {

    }

    &__phone {

    }

    &__schedule {
      padding-bottom: 26px;
    }

    &__link {

    }
  }
}

@include respond('medium') {
  .recall {
    &__top-wrapper {
      padding: 29px 40px 51px 35px;
    }

    &__phone-schedule-wrapper {
      flex: 0 0 50%;
    }

    &__phone {

    }

    &__text {
      flex: 0 0 50%;
    }

    &__schedule {
      padding-right: 100px;
    }

    &__link {

    }
  }
}

@include respond-down('medium') {
  .recall {
    &__top-wrapper {

    }

    &__phone-schedule-wrapper {

    }

    &__phone {

    }

    &__schedule {

    }

    &__link {

    }
  }
}

@include respond-down('small') {
  .recall {
    &__top-wrapper {
      flex-direction: column;
      padding: 29px 25px 34px;
    }

    &__phone-schedule-wrapper {
      padding-bottom: 18px;
    }

    &__phone {
      padding-bottom: 8px;
    }

    &__schedule {

    }

    &__link {

    }

    &__text {
      font-size: 18px;
      line-height: 31/18*1em;
    }
  }
}
