.service {
  &__seo-text{
    padding-top: 20px;
  }
  &__about-block {

  }

  &__about-info-wrapper {

  }

  &__label {

  }

  &__title {
    &_border-bottom {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--border-color-gray);
      }
    }
  }

  &__subtitle {
    font-weight: 500;
    color: var(--primary-color);
  }

  &__description {
    font-weight: normal;
    color: var(--color-another-gray);
  }

  &__recall-form-wrapper {

  }

  &__steps-block {

  }

  &__what-i-get-block {

  }

  &__payment-block {

  }
}

@include respond-up('large') {
  .service {
    margin: 100px 0;

    &__about-block {
      display: flex;
      justify-content: space-between;
      gap: 228px;
      margin-bottom: 44px;
    }

    &__about-info-wrapper {

    }

    &__label {
      margin-bottom: 30px;
    }

    &__title {
      padding-bottom: 30px;
    }

    &__subtitle {

    }

    &__description {
      padding-bottom: 56px;
    }

    &__recall-form-wrapper {
      flex: 0 0 282px;
    }

    &__steps-block {
      margin-bottom: 100px;
    }

    &__what-i-get-block {

    }

    &__payment-block {

    }
  }
}

@include respond-up('medium') {
  .service {
    &__title {
      &_space {
        margin-bottom: 50px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 27/18*1em;
      padding-bottom: 27px;
    }

    &__description {
      font-size: 15px;
      line-height: 24/15*1em;
    }
  }
}

@include respond('medium') {
  .service {
    margin: 60px 0;

    &__about-block {
      margin-bottom: 60px;
    }

    &__about-info-wrapper {

    }

    &__label {
      margin-bottom: 25px;
    }

    &__title {
      padding-bottom: 30px;
    }

    &__subtitle {

    }

    &__description {
      padding-bottom: 60px;
    }

    &__recall-form-wrapper {

    }

    &__steps-block {
      margin-bottom: 60px;
    }

    &__what-i-get-block {

    }

    &__payment-block {

    }
  }
}

@include respond-down('medium') {
  .service {
    &__about-block {

    }

    &__about-info-wrapper {

    }

    &__label {

    }

    &__title {
      &_space {
        margin-bottom: 20px;
      }
    }

    &__subtitle {

    }

    &__description {

    }

    &__recall-form-wrapper {

    }

    &__steps-block {

    }

    &__what-i-get-block {

    }

    &__payment-block {

    }
  }
}

@include respond-down('small') {
  .service {
    margin: 40px 0;

    &__about-block {
      margin-bottom: 40px;
    }

    &__about-info-wrapper {

    }

    &__label {
      margin-bottom: 20px;
    }

    &__title {
      padding-bottom: 25px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 24/16*1em;
      padding-bottom: 20px;
    }

    &__description {
      font-size: 15px;
      line-height: 24/15*1em;
      padding-bottom: 40px;
    }

    &__recall-form-wrapper {

    }

    &__steps-block {
      margin-bottom: 40px;
    }

    &__what-i-get-block {

    }

    &__payment-block {

    }
  }
}