.main-offer {
  overflow: hidden;

  &__slider-list {
    height: 100vh;
    position: relative;
  }

  &__slider-item {
    z-index: 200;

    .content-header__title {
      &_offer {
        transform: translateY(50px);
        opacity: 0;
        transition: {
          property: opacity, transform;
          duration: .6s;
          delay: .2s;
          timing-function: ease-in-out;
        };
      }
    }

    .content-header__subtitle {
      &_offer {
        transform: translateY(50px);
        opacity: 0;
        transition: {
          property: opacity, transform;
          duration: .6s;
          delay: .2s;
          timing-function: ease-in-out;
        };
      }
    }

    &__link {
      transform: translateY(50px);
      opacity: 0;
      transition: {
        property: opacity, transform;
        duration: .6s;
        delay: .2s;
        timing-function: ease-in-out;
      };
    }

    &._tns-normal {
      transition-duration: 0s !important;
      animation-duration: 0s !important;

      .main-offer__image {
        transform: translateY(0) scale(1.2);
      }
    }

    &.fade-in {
      transform: translateX(0);
      z-index: 4;
      transition: transform 1.5s;

      .content-header__title {
        &_offer {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .content-header__subtitle {
        &_offer {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .main-offer {
        &__link {
          opacity: 1;
          transform: translateY(0);
        }

        &__image {
          transform: translateY(0) scale(1);
          transition: transform 1.5s;
        }

        &__slider-list {
          //&:before {
          //  transform: translateX(0) scale(1);
          //  transition: transform 1.5s;
          //}
        }
      }
    }

    &.fade-out {
      z-index: 3;
      transform: translateX(0);
      overflow: visible;

      //.content-header__title {
      //  &_offer {
      //    opacity: 0;
      //    transform: translateY(0);
      //    transition:{
      //      delay: 0s;
      //      duration: .3s;
      //    }
      //  }
      //}
      //
      //.content-header__subtitle {
      //  &_offer {
      //    transform: translateY(0);
      //    opacity: 0;
      //    transition:{
      //      delay: 0s;
      //      duration: .3s;
      //    }
      //  }
      //}

      .main-offer {
        &__link {
          transform: translateY(0);
          opacity: 0;
          transition:{
            delay: 0s;
            duration: .3s;
          }
        }

        &__image {
          transform: translateY(20%);
          transition: transform 1.5s;
        }
      }
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    &:before {
      position: absolute;
      content: '';
      background: linear-gradient(180deg, rgba(29, 2, 15, 0.7) 18.57%, rgba(29, 2, 15, 0.434) 100%);
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      pointer-events: none;
      z-index: 100;
    }
  }

  &__offer-picture {

  }

  &__image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 10;
    //transform: translateY(0) scale(1.2);
  }

  &__content-wrapper {
    position: relative;
    z-index: 100;
  }

  &__link {
    display: block;
    width: 219px;
    padding: 12px 0;
    background: var(--color-orange);
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 27/17*1em;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 136px;
    transform: translateY(50px);
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: .6s;
      delay: .2s;
      timing-function: ease-in-out;
    };
  }

  &__slider-control {
    position: absolute;
    z-index: 10;
  }
}

@include respond-up('large') {
  .main-offer {
    &__content-wrapper {
      padding-top: 269/1980*100%;
    }

    &__slider-control {
      left: 3%;
      bottom: 60px;
    }
  }
}

@include respond-up('medium') {
  .main-offer{
    margin-bottom: 40px;
  }
}

@include respond-down('medium') {
  .main-offer {
    &__content-wrapper {
      padding-top: 141px;
    }

    &__image {
      height: 100%;
    }

    &__slider-control {
      left: 41/768*100%;
      bottom: 60px;
    }
  }
}

@include respond-down('small') {
  .main-offer {
    padding-bottom: 30px;
    &__content-wrapper {
      padding-top: 110px;
    }
  }
}
