.review-item {
  &__head {
    display: flex;
    align-items: center;
  }

  &__photo {
    border-radius: 100px;
    overflow: hidden;
    flex: 0 0 65px;
  }

  &__picture {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__name {
    font-weight: 500;
    line-height: 150%;
  }

  &__date {
  }

  &__content {
    color: var(--color-gray);
    line-height: 160%;
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__original {
    flex: 0 0 auto;
  }

  &__socials {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .review-item {



    &__photo {
    }


    &__date {
      flex: 0 0 auto;
      text-align: right;
    }
  }
}

@include respond-up('medium') {
  .review-item {
    padding: 30px 40px 40px;

    &__head {
    }

    &__content {
      margin: 25px 0;
      font-size: 16px;
    }

    &__photo {
      margin-right: 20px;
    }

    &__name {
      font-size: 20px;
      flex: 1 1 auto;
    }
  }
}

@include respond('medium') {
  .review-item {

  }
}

@include respond-down('medium') {
  .review-item {

  }
}

@include respond-down('small') {
  .review-item {
    padding: 20px 20px 30px;

    &__head {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__content {
      margin: 10px 0 23px;
      font-size: 15px;
    }

    &__photo {
      order: 3;
    }

    &__date {
      order: 5;
    }

    &__name {
      font-size: 18px;
      order: 10;
      flex: 0 0 100%;
      margin-top: 15px;
    }
  }
}