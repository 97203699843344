.article {
  padding: 80px 0 70px;



  &__content {
    grid-area: content;
  }

  &__content-wrap{
    padding: 0 30px 60px;
    border-bottom: 1px solid var(--border-color);
  }

  &__aside {
    grid-area: aside;
  }

  &__image {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 50px;
  }
}

@include respond-up('large') {
  .article {
    display: grid;
    grid-template-columns: 8fr 1fr 3fr;
    grid-template-areas: "content . aside";
  }
}

@include respond-down('medium') {
  .article {
    padding: 60px 0 60px;
    //grid-template-columns: auto;

    &__content-wrap{
      padding: 0 20px 50px;
    }

    &__aside {
      display: none;
    }

    &__image {
      margin-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .article {
    padding: 40px 0 50px;

    &__content-wrap{
      padding: 0 0 40px;
    }

    &__image {
      margin-bottom: 30px;
    }
  }
}