.services-page {
  &__category{
    opacity: 0;
    transition: 0.8s ease-in-out ;
    &.animate{
      opacity: 1;
    }
  }
  &__category-title{
    font-style: normal;
    font-weight: bold;
    line-height: 135%;
    color: var(--primary-color);
  }
  &__item{
    background: #FFFFFF;
    box-shadow: 0px 15px 35px rgba(54, 44, 40, 0.1);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &_orange{
      background: #F1D8CA;
    }
  }

  &__description{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: var(--gray-text);
    display: inline-block;
    &_orange{
      color: var(--color-brown-light);
    }
  }

  &__link{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link-text{
    font-style: normal;
    font-weight: normal;
    line-height: 135%;
    color: var(--primary-color);
  }

  &__consult-button{
    padding: 12px 16px;
    margin-top: 96px;
    max-width: 254px;
    background-color: var(--color-orange);
  }

  &__link-text{
    display: flex;
    svg{
      margin-right: 16px;
    }
  }

  &__info-heading{
    display: flex;
    align-items: center;
  }
  &__subtitle{
    margin-right: 12px;
    position: relative;
    &_relocation{
      &:after{
        position: absolute;
        content: '';
        background-image: url('../images/svg/arrow-small.svg');
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 181px;
        top: 46px;
      }
    }
  }

  &__info-arrow{
    margin-top: 6px;
  }

  &__seo-text{
    padding: 20px 0 100px 0;
  }
}

.smart-content{
  .services-page__category{
    padding-bottom: 0;
  }
}

@include respond-up('large') {
  .services-page{
    padding-top: 80px;
    &__realty-list{
      padding-bottom: 100px;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr 384px;
      grid-template-areas:
      'a a d'
      'b b d'
      'c c e';
    }

    &__category{
    padding-bottom: 100px;
    }

    &__category-title{
      padding-bottom: 48px;
    }

    &__description{
      padding-top: 20px;
      max-width: 252px;
    }

    &__flats{
      grid-area: a;
    }

    &__suburban{
      grid-area: b;

    }

    &__relocation{
      grid-area: c;
      height: 357px;
    }

    &__new{
      grid-area: d;
      flex-direction: column;
    }

    &__consult{
      grid-area: e;
    }

    &__wrap#first {
      margin-bottom: -24px;
    }

    &__mortgage{
      display: grid;
      grid-template-columns: 1fr 384px ;
      grid-gap: 24px;
    }

    &__law-service{
      height: 357px;
    }

    &__additional{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 317px;
      grid-gap: 24px;
    }

    &__picture{
      position: absolute;
      right: 0;
      bottom: -5px;
    }

    &__image{
      width: 350px;
      &_law{
        width: 100%;
      }
      &_add{
        width: 250px;
      }
    }

    &__info-wrap{
      flex: 0 50%;
    }
  }
  .services-page__main{
    .services-page__consult{
      display: none;
    }
    .services-page__relocation{
      display: none;
    }
  }
}


@include respond-up('medium'){
  .services-page{
    &__item{
      padding: 39px 0 56px 45px;
    }

    &__link-wrap{
      display: flex;
      flex-direction: column;
      padding-top: 12px;
    }

    &__link{
      margin-top: 22px ;
      padding: 32px 22px 31px 22px;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 10px;
    }

    &__link-text{
      font-size: 20px;
    }

    &__item-flex{
      display: flex;
    }
  }
}

@include respond('medium'){
  .services-page{
    &__item{
      max-height: 385px;
      min-height: 315px;
    }

    &__description{
      max-width: 252px;
    }

    &__info-wrap{
      flex: 0 60%;
    }

    &__image{
      width: 300px;
      &_law{
        width: 500px;
      }
      &_add{
        width: 250px;
      }
      &_new{
        height: 450px;
      }
    }

    &__picture{
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
  }
}

@include respond-down('medium') {
  .services-page{
    padding-top: 60px;
    &__realty-list{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    &__description{
      padding-top: 12px;
    }
    &__item{
      &_add{
      }
    }

    &__item-flex{
      display: flex;
    }

    &__mortgage{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    &__additional{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 315px;
      grid-gap: 20px;
    }


    &__picture{
      position: absolute;
      &_new{
        bottom: -100px;
      }
      &_add{
        transform: none;
        bottom: -5px;
        right: 0;
      }
      &__link{
        margin-top: 12px;
      }
    }

    &__category{
      padding-bottom: 60px;
    }

    &__category-title{
      padding-bottom: 30px;
    }
    &__consult{
      display: none;
    }
  }
}

@include respond-down('small') {
  .services-page{
    &__subtitle{
      z-index: 10;
    }
    &__info-wrap{
      z-index: 10;
      flex: 0 50%;
    }
    &__description{
      padding-top: 8px;
    }

    &__item-flex{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 215px;
      min-height: 409px;
    }

    &__item{
      padding: 20px 25px 0;
      min-height: 166px;
      &_add{
        height: 181px;
      }
    }

    &__picture{
      position: absolute;
      right: 0;
      bottom: -5px;
      &_new{
        bottom: -100px;
      }
    }

    &__image{
      width: 200px;

      &_add{
        width: 170px;
      }

      &_law{
        width: 250px;
      }

      &_new{
        height: 325px;
      }
    }

    &__additional{
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__link-wrap{
      display: flex;
      flex-direction: column;
      padding-top: 12px;
    }

    &__link{
      margin-top: 14px ;
      padding: 17px 20px 16px 20px;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 10px;
    }

    &__link-text{
      font-size: 18px;
    }
  }
}
