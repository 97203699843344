.breadcrumbs {

  &__list {

  }

  &__item {
    &_delimiter {

    }
  }

  &__link {

  }

  &__name {
    padding: 50px 0 33px;
  }

  &__list{
    font-size: 12px;
  }

  &__item{
    display: inline-block;

    a, span {
      text-decoration: none;
      color: var(--color-gray);


      &:hover{
        color: var(--primary-color);
      }
    }

    &:last-child {
      &, a {
        color: var(--color-gray);
      }
    }
  }

  &__item_delimiter{
    padding: 5px;
    color: var(--color-orange);
  }
}