.recall-form {
  background-color: white;
  position: relative;

  &__inner {
    display: flex;
  }

  &__info {
  }

  &__form {
    &.success {
      .recall-form {
        &__success {
          opacity: 1;
        }

        &__success-icon, &__success-title, &__success-subtitle {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__title {
    font-weight: bold;
    line-height: 120%;
    color: var(--primary-color);
  }

  &__subtitle {
    color: var(--color-gray);
    line-height: 160%;
  }

  &__fields {
  }

  &__field {
    position: relative;

    .errors {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
  }

  &__button-wrapper {
    display: flex;
  }

  &__policy {
    max-width: 270px;
  }

  &__success {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    transition: {
      property: opacity;
      duration: .2s;
    };
  }

  &__success-icon {
    background: white;
    box-shadow: 0 25px 35px rgba(54, 44, 40, 0.1);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 87px;
    opacity: 0;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .1s;
    };
  }

  &__success-title {
    font-weight: bold;
    font-size: 35px;
    line-height: 135%;
    text-align: center;
    color: var(--primary-color);
    margin: 25px 0 15px;
    opacity: 0;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .2s;
    };
  }

  &__success-subtitle {
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    color: var(--color-gray);
    opacity: 0;
    transform: translateY(10px);
    transition: {
      property: opacity, transform;
      duration: .2s;
      delay: .3s;
    };
  }
}

@include respond-up('large') {
  .recall-form {

    &__inner {
      justify-content: space-between;
      padding: 100px 0 112px;
    }

    &__form {
      flex: 0 0 524px;
    }

    &__title {
      font-size: 50px;
    }

    &__fields {
      padding-bottom: 3px;
    }

    &__field {
      margin-bottom: 49px;
    }

    &__subtitle {
      margin-top: 11px;
      max-width: 472px;
      font-size: 16px;
    }

    &__button-wrapper {
      justify-content: space-between;
    }
  }
}

@include respond-up('medium') {
  .recall-form {

  }
}

@include respond('medium') {
  .recall-form {

    &__inner {
      padding: 60px 20px;
    }

    &__subtitle {
      max-width: 393px;
      margin-bottom: 40px;
    }

    &__fields {
      display: grid;
      grid-gap: 46px;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 60px;
    }

    &__policy {
      margin-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .recall-form {

    &__inner {
      flex-direction: column;
      padding: 40px 8px 50px;
    }

    &__title {
      font-size: 30px;
    }

    &__subtitle {
      font-size: 15px;
    }
  }
}

@include respond-down('small') {
  .recall-form {

    &__subtitle {
      max-width: 314px;
      margin: 15px 0 30px;
    }

    &__fields {
      padding-bottom: 10px;
    }

    &__field {
      margin-bottom: 30px;
    }

    &__button-wrapper {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__button {
      flex: 0 0 100%;
      margin-bottom: 10px;
    }

    &__policy {
      text-align: center;
    }
  }
}