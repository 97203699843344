.advantages{
  &__another{

  }
}

@include respond-up('large'){
  .advantages{
    &__another{
      padding: 100px 0;
    }
    &__another-title{
      padding-bottom: 48px;
    }
  }
}

@include respond-down('medium'){
  .advantages{
    &__another{
      padding-top: 60px;
    }
    &__another-title{
      padding-bottom: 30px;
    }
  }
}

@include respond-down('small'){
  .advantages{
    &__another{
      padding-top: 40px;
    }
    &__another-title{
      padding-bottom: 20px;
    }
  }
}