.interests {
  position: relative;

  &__title {
    font-weight: bold;
    color: var(--primary-color);
  }

  &__slider-wrapper {

  }

  &__slider-list {
    display: flex;
  }

  &__slider-item {
    background: #FFFFFF;
    border-radius: 10px;
  }

  &__slider-item-wrapper {
    display: flex !important;
  }

  &__slider-caption {
    color: var(--primary-color);
  }

  &__points-container {

  }

  &__points-item {
    font-weight: normal;
    color: var(--gray-text);
    position: relative;

    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 10px;
      background: var(--color-orange);
      width: 6px;
      height: 6px;
      border-radius: 50px;
    }
  }

  &__slider-control {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@include respond-up('large') {
  .interests {
    margin-bottom: 73px;

    &__title {

    }

    &__slider-wrapper {

    }

    &__slider-list {
    }

    &__slider-item {
      flex: 0 0 33.3%;
      max-width: 384px;
      min-width: 384px;
      padding: 40px 45px;
    }

    &__slider-caption {

    }

    &__points-container {

    }

    &__points-item {

    }
  }
}

@include respond-up('medium') {
  .interests {
    &__title {
      font-size: 25px;
      line-height: 34/25*1em;
      padding-bottom: 30px;
    }

    &__slider-caption {
      font-size: 20px;
      line-height: 27/20*1em;
      padding-bottom: 20px;
    }

    &__points-item {
      font-size: 15px;
      line-height: 24/15*1em;
      padding-bottom: 20px;
      padding-left: 22px;
    }
  }
}

@include respond-down('medium') {
  .interests {
    &__slider-control {
      display: none;
    }
  }
}

@include respond('medium') {
  .interests {
    margin-bottom: 50px;

    &__title {

    }

    &__slider-wrapper {
      .tns-outer {
        margin: 0 -#{$spacer-medium}px;
      }
    }

    &__slider-list {
      margin: 0 #{$spacer-medium}px;
    }

    &__slider-item {
      padding: 30px 35px;
      flex: 0 0 50%;
      max-width: 334px;
    }

    &__slider-caption {

    }

    &__points-container {

    }

    &__points-item {

    }
  }
}

@include respond-down('small') {
  .interests {
    margin-bottom: 30px;

    &__title {
      font-size: 20px;
      line-height: 27/20*1em;
      padding-bottom: 17px
    }

    &__slider-wrapper {
      .tns-outer {
        margin: 0 -#{$spacer-small}px;
      }
    }

    &__slider-list {
      margin: 0 #{$spacer-small}px;
    }

    &__slider-item {
      padding: 20px;
      flex: 0 0 100%;
    }

    &__slider-caption {
      font-size: 18px;
      line-height: 24/18*1em;
      padding-bottom: 10px;
    }

    &__points-container {

    }

    &__points-item {
      font-size: 13px;
      line-height: 21/13*1em;
      padding-bottom: 10px;
      padding-left: 18px;
    }
  }
}