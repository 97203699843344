.arrows {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;


  &__arrow-right-link {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: var(--color-orange);
    svg {

      path {
        stroke: var(--color-orange);
      }
    }
  }

  &__arrow-right-arrow {
    margin-top: 5px;
  }

  &__arrow-right-text {
    margin-right: 7px;
  }

  &__wrap{
    display: flex;
    margin-top: 4px;
  }

  &__link {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--primary-color);
    padding-right: 20px;

    svg {
      path {
        transition: var(--default-transition);
      }
    }

    &:hover {
      svg {
        path {
          stroke: #FF9457 !important;
        }
      }
    }
  }

  &__counter {
    display: inline-block !important;
    font-family: var(--h-font);
    color: var(--primary-color);
    margin-right: 15px;
  }

  &__current-index{
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 155%;
    color: var(--primary-color);

    &_white {
      color: #FFFFFF;
    }
  }

  &__counter-all, &__counter-delimiter {
    color: var(--color-gray);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
  }

  &__delimiter {
    margin-right: 2px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}