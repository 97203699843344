article {
  h1, h2, h3, h4, h5, h6, strong {
    color: var(--primary-color);
  }

  h1, h2, h3, h4, h5, h6 {
    padding-top: 20px;
  }

  h1, h2 {
    padding-bottom: 40px;
  }

  h3, h4, h5, h6, p {
    padding-bottom: 30px;
  }

  li, ul, ol {
    padding-bottom: 15px;
  }

  ol {
    padding-left: 30px;
  }

  ul {
    padding-left: 15px;
  }

  &, p, li {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: var(--color-gray);
  }

  ul {
    list-style-type: decimal;
  }

  ul li {
    padding-left: 15px;
  }

  ul li::marker {
    color: var(--color-orange);
    font-weight: bold;
    font-size: 18px;
  }

  ol li {
    position: relative;

    &:before {
      position: absolute;
      content: "—";
      left: -30px;
      color: var(--color-orange);
    }
  }

  img {
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }

  table {
    width: 100%;
    margin-bottom: 30px;
  }

  table, th, td {
    border: 1px solid var(--color-gray);
  }

  th, td {
    padding: 5px;
  }

  th {
    color: var(--primary-color);
    font-weight: bold;
  }

  strong {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    position: relative;
    &::before {
      content: "";
      background: {
        size: contain;
        repeat: no-repeat;
        position: center;
        image: url("/static/images/svg/quote.svg");
      };
      position: absolute;
      top: 20px;
      left: 0;
      width: 39px;
      height: 30px;
    }

    p {
      color: var(--primary-color);
      font-size: 20px;
      line-height: 34px;
      font-style: italic;
      padding-top: 26px;
      padding-bottom: 50px;
      padding-left: 60px;
    }
  }
}

@include respond-down('medium') {
  article {
    h1, h2 {
      padding-bottom: 30px;
    }

    h3, h4, h5, h6, p {
      padding-bottom: 20px;
    }

    ul, ol {
      padding-bottom: 10px;
    }

    blockquote {
      p {
        padding-left: 52px;
        padding-bottom: 40px;
        padding-top: 20px;

        &::before {
          width: 32px;
          height: 25px;
        }
      }
    }
  }
}

@include respond-down('small') {
  article {
    h1, h2, h3, h4, h5, h6 {
      padding-top: 10px;
    }

    &, p, li {
      font-size: 15px;
      line-height: 24px;
    }

    strong {
      font-size: 16px;
      line-height: 24px;
    }

    blockquote {
      p {
        font-size: 18px;
        line-height: 30px;
        padding-left: 48px;
        padding-bottom: 30px;
        padding-top: 10px;

        &::before {
          width: 27px;
          height: 21px;
        }
      }
    }

    table {
      display: none;
    }
  }
}