.about-us {
  &__slider-container {
    .tns-nav {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      button {
        padding: 0;
        appearance: none;
        width: 8px;
        height: 8px;
        background-color: var(--border-color);
        border-radius: 100%;
        margin: 0 5px;
        border: none;
      }

      .tns-nav-active {
        background-color: var(--color-orange);
      }
    }

  }

  &__item-title {
    color: var(--color-orange);
    font-weight: bold;
    line-height: 120%;
  }

  &__slider {
    border-radius: 10px;
  }

  &__list {
    display: flex;
    background-color: white;
    border-radius: 10px;
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      background-color: var(--border-color);
    }

  }

  &__slider-arrows {
    display: none;
  }
}

@include respond-up('large') {
  .about-us {
    padding: 80px 0 60px;

    &__slider-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 126px;
      margin-bottom: 68px;
    }

    &__text-wrapper {
      padding-top: 20px;
    }

    &__label {
      margin-bottom: 30px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__item-title {
      font-size: 35px;
      margin-bottom: 12px;
    }

    &__item-subtitle {
      max-width: 190px;
    }

    &__slider {
      object-fit: cover;
      width: 100%;
      height: 369px;
    }

    &__item {
      padding: 60px 55px 69px 50px;
      position: relative;

      &::before {
        height: 158px;
        top: 40px;
        right: 0;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

@include respond('medium') {
  .about-us {
    padding: 60px 0;

    &__slider-container {
      margin-bottom: 60px;
      position: relative;
    }

    &__label {
      margin-bottom: 25px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 40px;

    }

    &__slider-arrows {
      display: flex;
      position: absolute;
      bottom: 50px;
      right: 20px;
    }

    &__item-title {
      margin-bottom: 10px;
    }

    &__list {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 50%;
      padding: 45px 65px 60px 51px;

      &:nth-child(2) {
        order: 3;
        padding-top: 0;
      }

      &:nth-child(4) {
        padding-top: 0;
        order: 4;
      }

      &:nth-child(3) {
        order: 2;
      }
    }
  }
}

@include respond-down('medium') {
  .about-us {

    &__slider {
      width: 100%;

    }

    &__item-title {
      font-size: 30px;
    }

    &__item-subtitle {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .about-us {
    padding: 40px 0;

    &__slider-container {
      margin-bottom: 18px;
    }

    &__label {
      margin-bottom: 25px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 25px;
    }

    &__slider {
      object-fit: cover;
    }

    &__list {
      flex-direction: column;

    }

    &__item {
      padding: 30px 0 25px 0;
      border-bottom: 1px solid var(--border-color);
      margin: 0 25px;

      &:last-child {
        padding-bottom: 45px;
        border-bottom: none;
      }
    }

    &__item-title {
      margin-bottom: 7px;
    }

    &__item-subtitle {
      max-width: calc((190 / 375) * 100vw);
    }
  }
}