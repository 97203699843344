.news {
  padding: 80px 0 100px;

  &__item{
    display: contents;
  }

  &__list {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 40px;
  }
}

@include respond-down('medium') {
  .news {
    padding: 60px 0 80px;

    &__list {
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 30px;
    }
  }
}

@include respond-down('small') {
  .news {
    padding: 40px 0 60px;

    &__list {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
    }
  }
}