.review-modal {

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    margin-bottom: 13px;
    max-width: 344px;
  }

  &__subtitle {
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.35em;
    color: var(--color-gray);
    margin-bottom: 40px;
    max-width: 364px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-row-gap: 40px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    padding: 15px 83px 16px;
    margin-bottom: 20px;
  }

  &__policy {
    max-width: 270px;
    text-align: center;
  }

  &__field {
    position: relative;
    textarea {
      height: 37px;
      padding: 11px 0 7px;
      &::-webkit-scrollbar{
        display: none;
      }
    }

    .errors {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
  }
}

@include respond-up('medium') {
  .review-modal {
    padding: 20px 20px 30px;
    min-width: 424px;
  }
}

@include respond-down('small') {
  .review-modal {
    padding: 30px;
  }
}