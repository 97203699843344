.pagination-endless {
  &__nav {
    display: flex;
    justify-content: center;
  }

  &__button {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: 160%;
    letter-spacing: 0.02em;
    color: var(--color-orange);
    margin: 20px 0;
  }

  &__button-text {

  }

  &__button-arrow {
    margin-left: 7px;
  }
}
