.form-button {
  background-color: rgba(87, 54, 50, 0.1);
  overflow: hidden;
  border-radius: 10px;

  &__back {
    position: absolute;
    z-index: 20;
    right: 0;
    background: {
      image: url("/static/images/svg/building-gradient.svg");
      repeat: no-repeat;
    };
  }

  &__front {
    position: relative;
    z-index: 30;
  }

  &__title {
    font-weight: bold;
    line-height: 135%;
    color: var(--color-brown);
  }

  &__link {
  }
}

@include respond-up('large') {
  .form-button {
    &__back {
      left: 0;
      bottom: -10px;
      height: calc(100% - 5px);
      background: {
        position: left center;
        size: auto 100%;
      };

    }
    &__title {
      font-size: 25px;
      margin-bottom: 38px;
    }

    &__front {
      padding: 30px 30px 40px;
    }

    &__link {
      width: 100%;
      padding: 12px 0;
    }
  }
}

@include respond-up('medium') {
  .form-button {

  }
}

@include respond('medium') {
  .form-button {

    &__back {
      width: 307px;
      top: 0;
      bottom: 0;
      background: {
        position: left top -17px;
        size: auto 240px;
      };
    }
    &__front {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include respond-down('medium') {
  .form-button {
    &__title {
      font-size: 24px;
    }

    &__front {
      padding: 30px;
    }
  }
}

@include respond-down('small') {
  .form-button {
    &__back {
      left: 51px;
      top: 15px;
      bottom: 0;
      background: {
        position: left top;
        size: auto 221px;
      };
    }

    &__front {
      padding: 30px 20px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__link {
      width: 100%;
    }
  }
}