h1, h2, h3, h4, h5, h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  line-height: var(--h-lineheight);
  font-weight: bold;
  color: var(--primary-color);
}

h1 {
  --h-size: 50px;
  --h-lineheight: 120%;
}

h2 {
  --h-size: 35px;
  --h-lineheight: 135%;
}

h3 {
  --h-size: 25px;
  --h-lineheight: 135%;
}

h4 {
  --h-size: 24px;
  --h-lineheight: 135%;
}

h5 {
  --h-size: 22px;
  --h-lineheight: 135%;
}

h6 {
  --h-size: 20px;
  --h-lineheight: 135%;
}

@include respond-down('small') {
  h2 {
    --h-size: 24px;
    --h-lineheight: 135%;
  }
}

@include respond-down('medium'){
  h1 {
    --h-size: 45px;
  }
}

@include respond-down('small'){
  h1 {
    --h-size: 30px;
  }
}