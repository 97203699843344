.partners {
    &__main {
     display: flex;
    }

    &__wrapper {

    }

    &__title {
      font-weight: 700;
      color: var(--primary-color);
    }

    &__description {
      font-weight: 400;
      color: var(--color-gray);
    }

    &__image {
      overflow: hidden;
      border-radius: 10px;
    }

    &__main-image {

    }
    &__main-picture{
      display: flex;
    }

    &__list-wrapper{

    }

    &__list{

    }

    &__list-item{
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
      }
    }

@include respond-up('large') {
  .partners {
    &__main {
      padding-top: 80px;
      padding-bottom: 82px;
      justify-content: space-between;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    &__title {
      font-size: 25px;
      line-height: 34px;
      letter-spacing: 0em;
      padding-bottom: 25px;
      max-width: 485px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0em;
      max-width: 459px;
    }

    &__image {
      flex: 0 0 49%;
    }

    &__main-image {

    }

    &__list-wrapper {
      padding-bottom: 200px;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr 33%;
      grid-gap: 24px;
    }

    &__list-item{
      box-shadow: 0px 15px 35px rgba(54, 44, 40, 0.1);
      transition: var(--default-transition);

      &:hover{
        box-shadow: 0px 15px 45px rgba(54, 44, 40, 0.25);

      }
    }
  }
}

@include respond-down('medium'){
  .partners {
    &__main {
      flex-direction: column-reverse;
      padding-bottom: 30px;
    }

    &__wrapper {
      max-width: 571px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0em;
      padding-bottom: 20px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0em;
    }

    &__image {
      padding: 60px 0;
    }

    &__main-image {
      width: 100%;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }
}

@include respond-down('small') {
  .partners {
    &__main {

    }

    &__wrapper {

    }

    &__title {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: 0em;
      min-width: auto;
    }

    &__description {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0em;
      min-width: auto;
    }

    &__image {
      padding: 40px 0;
      width: 100%;
      border-radius: 20px;
    }

    &__main-image {

    }

    &__list-wrapper {
      padding-bottom: 160px;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }
}