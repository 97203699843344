.recall-modal {
  padding: 50px 20px 55px;
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 384px;
  }

  &__title {
    text-align: center;
    margin-bottom: 12px;
  }

  &__subtitle {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6em;
    color: var(--color-gray);
    margin-bottom: 50px;
  }

  &__form {
    width: 100%;
    display: grid;
    grid-row-gap: 50px;
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    padding: 15px 83px 16px;
    margin-bottom: 20px;
  }

  &__policy {
    max-width: 270px;
    text-align: center;
  }

  &__field {
    position: relative;

    .errors {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
  }
}