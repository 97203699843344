.service-offer {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    background: linear-gradient(180deg, rgba(29, 2, 15, 0.7) 18.57%, rgba(29, 2, 15, 0.434) 100%);
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 20;
  }

  &__content-wrapper {
    position: relative;
    z-index: 100;
  }

  &__image-wrapper {
    position: relative;
    z-index: 10;
  }

  &__offer-picture {
  }

  &__image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__image {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
  }

  &__link {
    display: block;
    width: 219px;
    padding: 12px 0;
    background: var(--color-orange);
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    line-height: 27/17*1em;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 136px;
  }

  &__tabs-container {

  }

  &__tab-list {

  }

  &__tab-item {

  }

  &__tab-link {
    color: #FFFFFF;
  }
}

@include respond-up('large') {
  .service-offer {
    &__image-wrapper {

    }

    &__image {
      height: 100%;
    }

    &__image-container {
      height: calc(100vh - 15px)
    }

    &__content-wrapper {
      padding-top: 159px;
    }
  }
}

@include respond-up('medium') {

}

@include respond('medium') {
  .service-offer {
    &__link {
      margin-bottom: 229px;
    }

    &__content-wrapper {
      padding-top: 96px;
    }

    &__image-container {
      height: calc(100vh - 150px);
    }

    &__image {
      height: 100%;
      object-fit: cover;
    }
  }
}

@include respond-down('medium') {
  .service-offer {
    &__tabs-container {
      height: 37px;

      .tabs {
        padding-bottom: 10px;
      }
    }
  }
}

@include respond-down('small') {
  .service-offer {
    &__content-wrapper {
      padding-top: 114px;
    }

    &__image-container {
      height: 100vh;
    }

    &__image {
      height: 100%;
      object-fit: cover;
    }
  }
}