.reviews-page {
  &__video-block {
  }

  &__text-block {
    display: grid;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    grid-area: title;
  }

  &__form-wrapper {
    grid-area: form;
    position: relative;
  }

  &__form {
  }

  &__text-reviews {
    grid-area: reviews;
  }

  &__slider-wrapper {
  }

  &__slider {
  }

  &__slide-item {
  }

  &__arrows {
  }

  &__slide-link {
    position: relative;
    display: flex;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
      .reviews-page {
        &__play {
          &::before {
            background-color: var(--color-orange-hover);
            transform: scale(1.1);
          }
          &::after {
            transform: scale(1.4);
            opacity: 0;
          }
        }
      }
    }
  }

  &__video-front {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(57, 43, 42, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__play {
    position: relative;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      z-index: 20;
    }

    &::before, &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100px;
      transform: scale(1);
      opacity: 1;
      background-color: var(--color-orange);
      transition: {
        property: background-color, transform, opacity;
        timing-function: ease-in-out;
        duration: .5s;
      };
    }

    &::before {
      z-index: 15;
    }

    &::after {
      z-index: 10;
    }
  }

  &__preview-picture {
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__preview-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

}

@include respond-up('large') {
  .reviews-page {
    padding-top: 80px;

    &__video-block {
      padding-bottom: 80px;
    }

    &__text-block {
      padding-bottom: 154px;
    }

    &__text-block {
      grid-template-columns: 1fr 279px;
      grid-gap: 0 23px;
      grid-template-areas:
              "title title"
              "reviews form";
    }

    &__slide-link {
      height: 216px;
    }

    &__form {
      position: sticky;
      top: 140px;
    }

    &__review {
      margin-bottom: 24px;
    }
  }
}

@include respond-up('medium') {
  .reviews-page {
  }
}

@include respond('medium') {
  .reviews-page {
    padding-top: 65px;

    &__video-block {
      padding-bottom: 65px;
    }

    &__text-block {
      padding-bottom: 140px;
    }

    &__text-block {
      padding-bottom: 154px;
    }

    &__form-wrapper {
      margin-bottom: 60px;
    }

    &__slide-link {
      padding-bottom: calc(121 / 216 * 100%);
    }

    &__preview-picture {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
}

@include respond-down('medium') {
  .reviews-page {
    &__text-block {
      grid-template-areas:
              "form"
              "title"
              "reviews";
    }

    &__review {
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .reviews-page {
    padding-top: 40px;

    &__video-block {
      padding-bottom: 40px;
    }

    &__text-block {
      padding-bottom: 110px;
    }

    &__form-wrapper {
      margin-bottom: 40px;
    }

    &__arrows {
      display: none;
    }

    &__slider-wrapper {
      margin: 0 -#{$spacer-small}px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 79px;
        background: linear-gradient(270deg, #F7F1F0 9.83%, rgba(247, 241, 240, 0) 98.02%);
        z-index: 200;
        pointer-events: none;
      }
    }

    &__slider {
      &::before {
        content: '';
        width: #{$spacer-small}px;
        display: inline-block;
      }
    }

    &__slide-link {
      height: 121px
    }
  }
}