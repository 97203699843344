.error-page {
  padding: 100px 0;
  &__wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__code{
    font-size: 100px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 40px;
  }

  &__text{
    padding-bottom: 30px;
  }
}

@include respond-up('large') {

}

@include respond-up('medium') {
  .error-page {

  }
}

@include respond('medium') {
  .error-page {

  }
}

@include respond-down('medium') {
  .error-page {

  }
}

@include respond-down('small') {
  .error-page {

  }
}