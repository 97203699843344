:root {
  --default-input-height: 37px;
  --errors-color: var(--alert-color, #b50000)
}

.button {
  padding: 12px 65px;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  background: var(--color-orange);
  color: white;
  border-radius: 8px;

  &:hover{
    background-color: var(--color-orange-hover);
  }

  &.secondary{
    background-color: var(--color-brown);
  }

  &_expand, &_wide {
    width: 100%;
  }

  &.height {
    height: var(--default-input-height);
  }
}

.policy{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  color: var(--color-gray);

  a{
    color: var(--color-orange);
  }
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 7px;
    list-style: none;
    color: var(--errors-color);
    font-size: 12px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: none;
    border-bottom: 2px solid var(--border-color);
    background-color: var(--t);
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);

    &.invalid{
      border-bottom: 2px solid var(--alert-color);
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  input {
    &::placeholder {
      color: var(--color-gray);
    }
  }
}