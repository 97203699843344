.sitemap{
  padding: 50px 0;
  list-style: square;
  li{
    padding-left: 20px;
  }

  ul{
    list-style: circle;
  }
}

@include respond-up('large'){
  .sitemap{

  }
}

@include respond('medium'){
  .sitemap{

  }
}

@include respond-down('small'){
  .sitemap{

  }
}