.history-main {
  &__label {

  }

  &__main-container {
    display: grid;
  }

  &__description-block {

  }
  &__title-wrap{
    overflow: hidden;
  }

  &__title {
    transition: all .6s;
    transform: translateY(100px);
    opacity: 0;

    &.animate{
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__description-wrap{
    overflow: hidden;
  }

  &__description {
    transition: all .8s;
    transform: translateY(100px);
    opacity: 0;

    &.animate{
      opacity: 1;
      transform: translateY(0);
    }
  }


  &__list {
    background-color: white;
    flex-wrap: wrap;
    display: flex;
    box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
    border-radius: 10px;
  }

  &__item {
    flex: 0 0 50%;
  }

  &__item-wrap {

  }

  &__item-title {
    color: var(--color-orange);
    font-weight: bold;
    line-height: 135%;
  }

  &__item-description {
    line-height: 160%;
    color: var(--primary-color);
  }

  &__button {

  }

  &__slider-block {
    position: relative;
  }

  &__arrows {
    position: absolute;

  }

  &__slider-list {
    overflow: hidden;
  }

  &__slider-item {
    &.fade-in{
      .history-main{
        &__name-wrapper{
          opacity: 1;
          transform: translateX(0);
          transition: .7s all;
        }
      }
    }

    &.fade-out{
      .history-main{
        &__image-wrap{
          opacity: 0;
          transition: .5s all;
        }
        &__name-wrapper{
          transform: translateX(100%);
          opacity: 0;
          transition: .5s all;
        }
      }
    }
  }

  &__slider-wrapper {

  }

  &__image-wrap {

  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__picture {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: block;
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  &__profession {
    font-size: 15px;
    line-height: 160%;
    color: var(--gray-text);

  }

  &__quote {
    line-height: 170%;
    color: var(--primary-color);
    position: relative;
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);

    &::before {
      content: '';
      position: absolute;
      width: 29px;
      height: 23px;
      background: {
        image: url('../images/svg/icons/quote.svg');
        size: cover;
        repeat: no-repeat;
      };
    }
  }

  &__seo-text{
    padding: 20px 0;
  }
}

@include respond-up('large') {
  .history-main {
    margin-bottom: 110px;

    &__label {
      margin-bottom: 30px;
    }

    &__main-container {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 24px;

    }

    &__description-block {
      grid-column: 1/8;
    }

    &__title {
      max-width: 350px;
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 70px;
      max-width: 490px;
    }

    &__list {
      padding: 55px 51px;
      margin-bottom: 40px;

      &_mobile {
        display: none;
      }
    }

    &__item {
      &:nth-child(1) {
        margin-bottom: 62px;
      }

      &:nth-child(even) {
        padding-left: 50px;
        position: relative;

        &::before {
          background-color: var(--border-color);
          left: 0;
          top: 5px;
          width: 1px;
          height: 107px;
          content: '';
          position: absolute;
        }
      }
    }

    &__item-title {
      font-size: 35px;
      margin-bottom: 10px;
    }

    &__item-description {
      font-size: 16px;
      max-width: 190px;
    }

    &__button {

    }

    &__slider-block {
      grid-column: 9/13;
    }

    &__arrows {
      z-index: 20;
      top: 420px;
      right: 0;

      .arrows__current-index {
        color: white;
      }

      .arrows__link {
        svg {
          path {
            stroke: white;
          }
        }
      }
    }

    &__picture {
      padding-bottom: 496 / 384 * 100%;
      margin-bottom: 21px;

    }

    &__name {
    }

    &__profession {
      font-size: 15px;
      margin-bottom: 24px;
    }

    &__quote {
      padding: 29px 0 32px 53px;
      font-size: 20px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 40px;
      }
    }

    &__seo-text{
      grid-column: 1/ 13;
    }
  }
}

@include respond('medium') {
  .history-main {
    padding-bottom: 94px;

    &__label {
      margin-bottom: 25px;
    }

    &__main-container {
      display: flex;
      flex-direction: column;
    }

    &__description-block {

    }

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      max-width: calc((570 / 768) * 100vw);
      margin-bottom: 50px;
      font-size: 16px;
    }

    &__list {
      display: none;

      &_mobile {
        display: flex;
        padding: 45px 51px;
      }
    }

    &__item {
      &:first-child {
        margin-bottom: 60px;
      }

      &:nth-child(even) {
        padding-left: 40px;
      }
    }

    &__item-wrap {

    }

    &__item-title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__item-description {
      font-size: 16px;
      max-width: 190px;
    }

    &__button {
      display: none;
    }

    &__slider-block {
      margin-bottom: 50px;
    }

    &__arrows {
      bottom: 20px;
      left: calc((45 / 768) * 100vw);
      z-index: 10;
    }

    &__slider-wrapper {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
    }

    &__image-wrap {
      grid-column: 1/4;
      max-width: calc((279 / 768) * 100vw);
      min-height: calc((361 / 768) * 100vw);
    }

    &__name-wrapper {
      grid-column: 4/7;
      align-self: center;
    }

    &__picture {
      height: 100%;
      font-size: 16px;
    }

    &__name {
    }

    &__profession {
      margin-bottom: 27px;
    }

    &__quote {
      font-size: 20px;
      padding: 29px 10px 32px 53px;

      &::before {
        left: 0;
        top: 35px;
      }
    }
  }
}

@include respond-up('medium') {
  .history-main {
    &__quote {
      &_mobile {
        display: none;
      }
    }
    &__slider-block {
      .tns-nav {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .history-main {
    padding-bottom: 40px;
    &__label {
      margin-bottom: 20px;
    }

    &__main-container {

    }

    &__description-block {

    }

    &__title {
      margin-bottom: 20px;
    }

    &__description {
      margin-bottom: 30px;
    }

    &__list {
      display: none;

      &_mobile {
        display: flex;
        flex-direction: column;
      }
    }

    &__item {
      padding: 30px 0;
      margin: 0 25px;
      border-bottom: 1px solid var(--border-color);
      flex: 0 0 auto;
      &:last-child {
        border-bottom: none;
      }
    }

    &__item-wrap {

    }

    &__item-title {
      font-size: 30px;
      margin-bottom: 7px;
    }

    &__item-description {
      font-size: 16px;
      max-width: calc((190/375)*100vw);
    }

    &__button {
      display: none;
    }

    &__slider-block {
      .tns-nav {
        display: flex;
        position: absolute;
        top: calc((170/375)*100vw);
        left: calc((40/375)*100vw);
        button {
          width: 12px;
          height: 12px;
          margin: 0 5px;
          border-radius: 100%;
          background-color: var(--border-color);
          border: none;
        }
        .tns-nav-active {
          background-color: var(--color-orange);
          border: none;
        }
      }
    }

    &__arrows {
      display: none;
    }

    &__slider-list {
      position: relative;

    }

    &__slider-item {

    }

    &__slider-wrapper {
      display: flex;
      margin-bottom: 48px;
    }

    &__image-wrap {
      flex: 0 0 calc((121/375)*100vw);
      height: calc((157/375)*100vw);
      margin-right: 25px;
    }

    &__image {

    }

    &__picture {
      width: 100%;
      height: 100%;
    }

    &__name-wrapper {
      align-self: flex-end;
    }

    &__name {

    }

    &__profession {

    }

    &__quote {
      display: none;
      &_mobile {
        display: block;
        padding: 20px 0 20px 50px;
        margin-bottom: 30px;
        &::before {
          left: 0;
          top: 25px;
        }
      }
    }
  }
}