.certificates {
  &__list {
    display: grid;
  }

  &__wrapper {
    display: grid;
    background-color: white;
    box-shadow: 0 15px 45px rgba(54, 44, 40, 0.2);
    border-radius: 10px;
    align-content: start;
    align-items: start;
    height: 100%;
  }

  &__image {
    border: 2px solid white;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 15px rgba(54, 44, 40, 0.1));
    border-radius: 8px;
    width: 100%;
  }

  &__image-wrapper {
    position: relative;
  }

  &__title {
    font-weight: bold;
    line-height: 130%;
  }

  &__subtitle {
    line-height: 160%;
  }

  &__picture {
    display: flex;
    width: 100%;
  }
}

@include respond-up('large') {
  .certificates {
    padding: 80px 0 140px;

    &__list {
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    &__image-wrapper {
      position: relative;

      &:hover {
        .certificates {
          &__hover-image {
            opacity: 1;
          }
          &__image {
            opacity: 0.4;
          }
        }
      }
    }

    &__image {
      transition: var(--default-transition);
    }

    &__hover-image {
      transition: var(--default-transition);
      opacity: 0;
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      z-index: 50;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__wrapper {
      padding: 30px 64px 30px 30px;
      grid-column-gap: 25px;
      grid-template-columns: 94px 1fr;
    }

    &__title {
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-size: 16px;

    }
  }
}
@include respond('medium') {
  .certificates {
    padding: 60px 0 120px;

    &__list {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }

    &__image {
    }

    &__wrapper {
      padding: 25px 160px 25px 25px;
      grid-column-gap: 24px;
      grid-template-columns: 73px 1fr;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 5px;
    }


  }
}

@include respond-down('medium') {
  .certificates {
    &__image-wrapper {
      position: relative;
    }
    &__hover-image {
      position: absolute;
      bottom: 5px;
      left: 5px;
      z-index: 10;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
@include respond-down('small') {
  .certificates {
    padding: 40px 0 90px;

    &__list {
      grid-row-gap: 16px;
    }

    &__image {
      width: 54px;
    }

    &__wrapper {
      padding: 20px;
      grid-column-gap: 24px;
      grid-template-columns: 54px 1fr;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-size: 15px
    }
  }
}