.tabs {
  line-height: 135%;
  list-style: none;
  display: flex;
  justify-content: flex-start;

  &__item {
    a, button {
      cursor: pointer;
      border: none;
      background-color: var(--t);
      display: block;
      border-bottom: 3px solid var(--t);
        transition: {
          property: border-bottom-color;
          duration: .4s;
        };
    }

    &._active {
      a, button {
        border-bottom-color: var(--color-orange);
      }
    }

    &:hover {
      a, button {
        border-bottom-color: var(--color-brown-opacity);
      }

      &._active {
        a, button {
          border-bottom-color: var(--color-orange);
        }
      }
    }
  }
}

@include respond-up('large') {
  .tabs {
    margin: 0 -20px;

    &__item {
      padding: 0 20px;

      a, button {
        padding-bottom: 16px;
        font-size: 18px;
      }
    }
  }
}

@include respond-down('medium') {
  .tabs {
    font-size: 15px;
    margin: 0 -13px;
    overflow-x: scroll;

    &__item {
      padding: 0 13px;
      white-space: nowrap;

      a, button {
        padding-bottom: 14px;
      }
    }
  }
}