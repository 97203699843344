.footer {
  background-color: var(--primary-color);
  position: relative;
  overflow: hidden;

  &__bottom {
    position: relative;
  }

  &__call {
    font-weight: bold;
    font-size: 20px;
    line-height: 155%;
    color: white;
    margin-bottom: 9px;
    display: block;
    transition: {
      property: color;
      duration: .4s;
    };

    &:hover {
      color: var(--color-orange-hover);
    }
  }

  &__address {
    font-size: 14px;
    line-height: 155%;
    color: white;
  }

  &__mail {
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    line-height: 155%;
    color: var(--color-orange);
    transition: {
      property: color;
      duration: .4s;
    };

    &:hover {
      color: var(--color-orange-hover);
    }
  }

  &__menu-block-list {
    display: grid;
    z-index: 55;
    position: relative;
  }

  &__menu-block-item {
    &:nth-child(1) {
      grid-area: nav1;
    }
    &:nth-child(2) {
      grid-area: nav2;
    }
    &:nth-child(3) {
      grid-area: nav3;
    }
    &:last-child {
      grid-area: lastChild;
    }

  }

  &__menu-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    color: white;
  }

  &__menu-link {
    display: inline-block;
    font-size: 14px;
    line-height: 135%;
    color: rgba(255, 255, 255, 0.6);

    transition: {
      property: color;
      duration: .4s;
    };

    &:hover {
      color: white;
    }
  }

  &__bottom-info {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
  }

  &__image {
    position: absolute;
    z-index: 50;
    opacity: 0.13;
  }

  &__info-copy, &__policy, &__info-rclass {
    font-size: 12px;
    line-height: 1em;
    color: var(--color-another-opacity);
  }

  &__info-copy {
    padding-right: 24px;
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

  }

  &__sitemap {
    padding-right: 24px;
    padding-left: 24px;
    position: relative;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

  }

  &__bottom-wrapper {
    display: flex;
  }

  &__policy {
    padding-left: 24px;
    transition: {
      property: color;
      duration: .4s;
    };

    &:hover {
      color: white;
    }
  }

  &__info-rclass-link {
    color: var(--color-orange);
    transition: {
      property: color;
      duration: .4s;
    };

    &:hover {
      color: var(--color-orange-hover);
    }
  }

  &__logo {
    display: flex;
  }

  &__number {
    display: block;
  }
}

@include respond-up('large') {
  .footer {

    &:before {
      right: 0;
      bottom: 0;
      width: 608px;
      height: 245px;
    }

    &__main-wrapper {
      padding: 50px 0 56px;
      border-bottom: 1px solid rgba(220, 218, 216, 0.4);
    }

    &__logo {
      margin-bottom: 43px;
    }

    &__address {
      margin-bottom: 49px;
    }

    &__email {
      margin-bottom: 40px;
    }


    &__menu-block-list {
      grid-template-areas: "lastChild nav1 nav2 nav3";
      grid-template-columns: 2fr 270px 1fr 1fr;
      grid-column-gap: 100px;
    }


    &__menu-title {
      margin-bottom: 20px;
    }

    &__menu-list {
    }

    &__menu-item {
    }

    &__menu-link {
      padding: 6px 0;
    }

    &__bottom {
      padding: 30px 0;
    }

    &__image {
      left: 694px;
      bottom: -108px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__main-wrapper {
      padding: 60px 0 0;
    }

    &__main-container {
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 60px;
    }

    &__logo {
      margin-bottom: 40px;
    }

    &__number {
      margin-bottom: 9px;
    }

    &__address {
      margin-bottom: 40px;
    }


    &__email {
    margin-bottom: 30px;
    }

    &__menu-block-list {
      grid-template-areas: "nav1 nav3"
                           "nav1 lastChild"
                           "nav2 lastChild"
                           "nav2 .";
      margin: -20px 0;
    }

    &__menu-block-item {
      padding: 20px 0;

      &:nth-child(3) {
        padding-bottom: 40px;
      }
    }

    &__menu-title {
      margin-bottom: 20px;

    }

    &__menu-list {
      margin: -6px 0;
    }

    &__menu-item {
      padding: 6px 0;
    }

    &__bottom {
      padding: 30px 0;
    }

    &__image {
      left: 229px;
      bottom: -34px;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__main-wrapper {
      padding: 40px 0 0;
    }

    &__main-container {
      padding-bottom: 0;
    }

    &__menu-block-list {
      grid-template-areas: "nav1"
      "nav2"
      "nav3"
      "lastChild";
      margin: -20px 0;
    }

    &__menu-block-item {
      &:nth-child(3) {
        padding-bottom: 20px;
      }

    }

    &__bottom-wrapper {
      flex-direction: column;
    }

    &__bottom-info {
      flex-direction: column;
    }

    &__info-copy {
      padding: 0;
      border: none;
    }

    &__policy {
      padding: 20px 0;
    }

    &__image {
      display: none;
    }

    &__wrapper {
      padding-bottom: 40px;
    }
  }
}
