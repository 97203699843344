.history {
  &__list {

  }

  &__list-item {

    &:before{
      content: '';
      top: 30px;
      position: absolute;
      height: 100%;
      border: 1px solid var(--color-light);
      z-index: 0;
    }

    &:last-child{
      &:before{
        display: none;
        padding-bottom: 0;
      }
    }
  }

  &__main-side {

  }

  &__main-side-content {

  }

  &__main-side-year {
    position: relative;
    font-style: normal;
    font-weight: bold;
    line-height: 80%;
    color: #fff;
  }

  &__text{
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    color: var(--gray-text);
  }

  &__main-side-img {
    border-radius: 10px;
    overflow: hidden;
  }

  &__main-side-image {

  }

  &__main-side-picture {

  }


  &__main-side-title {

  }

  &__main-side-link-wrap {

  }

  &__main-side-link {
    position: relative;
    font-style: normal;
    font-weight: bold;
    line-height: 160%;
    color: var(--color-orange);
    display: flex;
    align-items: center;

    svg{
      margin-left: 7px;
      path{
        stroke: var(--color-orange);
      }
    }
  }

  &__other-side {

  }

  &__other-side-content {

  }

  &__other-side-title {

  }


  &__other-side-img {
    border-radius: 10px;
    overflow: hidden;
  }

  &__other-side-image {

  }

  &__other-side-picture {

  }
}

@include respond-up('large'){
  .history{
    &__list{
      padding-top: 120px;
      padding-bottom: 100px;
    }

    &__list-item{
      position: relative;
      display: grid;
      grid-template-areas:
      'a b';
      grid-template-columns: 50% 50%;
      padding-bottom: 120px;

      &:before{
        top: 30px;
        left: 50%;
      }

      &:first-child{
        .history{
          &__main-side{
            text-align: right;
          }
          &__main-side-image{
            height: 160px;
            width: 180px;
            object-fit: contain;
          }
          &__first-wrapper{
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          &__main-side-title{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 6px;
            text-align: left;
          }
          &__main-side-image {
            padding-bottom: 25px;
          }
          &__main-side-text{
            max-width: 180px;
          }
        }
      }

      &:nth-child(even){
        .history{
          &__main-side{
            padding-left: 116px;
            padding-right: 0;
          }

          &__other-side{
            padding-right: 111px;
            padding-left: 0;
          }

          &__main-side-year{
            text-align: left;

            &:after{
              position: absolute;
              content: '';
              top: 26px;
              left: -124px;
              height: 19px;
              width: 19px;
              background-color: var(--color-orange);
              border-radius: 100%;
              z-index: 1;
            }
          }
        }
      }
    }

    &__list-item-reverse{
      grid-template-areas:
      'b a';
    }

    &__main-side{
      grid-area: a;
      padding-right: 111px;
    }

    &__other-side{
      grid-area: b;
      padding-left: 116px;
      padding-top: 15px
    }

    &__main-side-year{
      font-size: 150px;
      text-align: right;
      padding-bottom: 72px;

      &:after{
        content: '';
        position: absolute;
        top: 19px;
        right: -121px;
        width: 19px;
        height: 19px;
        background-color: var(--color-orange);
        border-radius: 100%;
        z-index: 1;
      }
    }

    &__title{
      padding-bottom: 30px;
    }

    &__main-side-image {
      padding-bottom: 60px;
    }

    &__other-side-text{
      padding-bottom: 60px;
    }

    &__main-side-text{
      padding-bottom: 36px;
    }

    &__year-tablet{
      display: none;
    }
  }
}

@include respond-up('medium'){
  .history{
    &__text{
      font-size: 16px;
    }
  }
}
@include respond-down('medium'){
  .history{
    &__list-item{
      position: relative;
      display: flex;
      flex-direction: column-reverse;

      &:before{
        top: 30px;
        left: -110px;
      }


      &:first-child{
        .history{
          &__img{
              padding-bottom: 0;
              padding-top: 0;
          }
          &__first-wrapper{
            padding-top: 55px;
            text-align: left;
            display: flex;
            align-items: center;
          }
          &__main-side-title{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            padding-bottom: 6px;
            text-align: left;
          }
          &__main-side-image {
            width: 128px;
            height: 114px;
            object-fit: contain;
          }
          &__main-side-text{
            font-size: 13px;
          }

          &__text-wrapper{
            margin-left: 33px;
          }
        }
      }
    }
    &__main-side-year{
      display: none;
    }

    &__year-tablet{
      position: relative;
      display: block;

      &:after{
        position: absolute;
        content: '';
        top: 26px;
        left: -119px;
        height: 19px;
        width: 19px;
        background-color: var(--color-orange);
        border-radius: 100%;
        z-index: 1;
      }
    }

    &__text{
      //padding-bottom: 50px;
    }

    &__img{
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__main-side-link-wrap{
      padding-top: 36px;
    }
  }
}
@include respond('medium'){
  .history{
    padding-left: 120px;

    &__list{
      padding-top: 60px;
      padding-bottom: 70px;
    }

    &__year-tablet{
      font-size: 100px;
      padding-bottom: 50px;
    }

    &__list-item{
      padding-bottom: 70px;
      &:last-child{
        padding: 0;
      }
    }

    &__title{
      padding-bottom: 30px;
    }
  }
}

@include respond-down('small'){
  .history{
    padding-left: 40px;
    &__title{
      padding-bottom: 15px;
      font-size: 18px;
    }

    &__text{
      font-size: 15px;
    }

    &__list{
      padding-top: 40px;
      padding-bottom: 50px;

    }

    &__list-item{
      padding-bottom: 50px;

      &:before{
        top: 15px;
        left: -40px;
      }

      &:first-child{
        .history {
          &__first-wrapper {
            padding-top: 30px;
          }

          &__text-wrapper{
            max-width: 191px;
          }
        }
      }
    }

    &__year-tablet{
      font-size: 70px;
      padding-bottom: 30px;

      &:after{
        position: absolute;
        content: '';
        top: 12px;
        left: -43px;
        height: 9px;
        width: 9px;
        background-color: var(--color-orange);
        border-radius: 100%;
        z-index: 1;
      }
    }

    &__img{
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__main-side-link-wrap{
      padding-top: 20px;
    }
  }
}