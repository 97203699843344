.worker-description {
  background-color: white;
  position: relative;
  padding: 20px 30px 60px 40px;
  box-shadow: 0 25px 35px rgba(54, 44, 40, 0.1);
  border-radius: 10px;
  width: 370px;
  z-index: 50;

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 40px;
    border: 10px solid transparent; border-right: 10px solid white;
  }

  &__profession {
    font-size: 15px;
    line-height: 160%;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
  }

  &__worker-description {
    font-size: 15px;
    line-height: 160%;
    margin-bottom: 20px;
  }

  &__worker-quote {
    font-style: italic;
    font-size: 15px;
    line-height: 160%;
    position: relative;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;

    &_quote {
      svg {
        width: 15px;
        height: 12px;
        margin-right: 10px;
      }
    }
  }

  &__socials {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
}

@include respond-up('large') {
  .worker-description {
    &__closer {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .worker-description {

    &::before {
      display: none;
    }

    &__closer {
      padding: 20px;
      position: absolute;
      top: 0;
      right: 0;
    }

    &__closer-image {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@include respond-down('small') {
  .worker-description {
    width: 350px;
  }
}