.socials {
  &__list {
    display: flex;
    margin: 0 -5px;

    &_brown {
      .socials__link {
        background-color: rgba(255, 148, 87, 0.2);
      }
    }
    &_grey {
      .socials__link {
        border: 1px solid var(--border-color);
        svg {
          path {
            fill: var(--color-gray);
          }
        }
      }
    }

    &_ligh-brown {
      .socials__link {
        border: 1px solid var(--border-color);
        svg {
          path {
            fill: var(--color-brown-gray);
          }
        }
      }
    }
  }

  &__item {
    padding: 0 5px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 100%;
  }
}