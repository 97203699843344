.main-menu {
  &__list {
    display: flex;
  }

  &__item {
    position: relative;
  }

  &__link {
    display: flex;
    color: var(--primary-color);
    align-items: center;
  }

  &__child-list {
  }

  &__child-item {

  }

  &__child-link {
    color: var(--color-gray);
    display: flex;
  }
}

@include respond-up('large') {
  .main-menu {

    &__list {
      height: 100%;
      margin: 0 -15px;
    }

    &__item {
      height: 100%;

      &:hover {
        .main-menu {
          &__link {
            &::before {
              opacity: 1;
            }
          }

          &__child-list {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &__link {
      font-size: 15px;
      line-height: 155%;
      padding: 0 15px;
      height: 100%;
      position: relative;
      transition: {
        property: color;
        duration: .3s;
        timing-function: ease-in-out;
      };

      &::before {
        content: '';
        position: absolute;
        height: 2px;
        left: 15px;
        right: 15px;
        top: calc(50% + (15px * 1.55 / 2) + 3px);
        background-color: var(--color-orange);
        opacity: 0;
        transition: {
          property: opacity;
          duration: .4s;
        };
      }
    }

    &__child-list {
      position: absolute;
      left: 15px;
      top: 100%;
      background-color: white;
      width: 292px;
      padding: 18px 0 17px;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0 10px 20px rgba(54, 44, 40, 0.1), inset 0 1px 0 rgba(54, 44, 40, 0.2);
      border-radius: 0 0 10px 10px;
      transition: {
        property: opacity;
        duration: .4s;
      };
    }

    &__child-link {
      padding: 7px 22px 8px 20px;
      position: relative;
      transition: {
        property: color;
        duration: .4s;
      };

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        background-color: var(--color-orange);
        left: 0;
        top: 7px;
        bottom: 8px;
        opacity: 0;
        transition: {
          property: opacity;
          duration: .4s;
        };
      }

      &:hover {
        color: var(--primary-color);

        &::before {
          opacity: 1;
        }
      }
    }

    &__link-arrow {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .main-menu {

  }
}

@include respond('medium') {
  .main-menu {

    &__list {
      padding: 19px 0 124px;
    }

    &__link {
      font-size: 18px;
      padding: 11px 0 9px;
    }

    &__child-list {
      padding-bottom: 11px;
    }

    &__child-link {
      padding: 6px 0 7px;
    }
  }
}

@include respond-down('medium') {
  .main-menu {

    &__list {
      flex-direction: column;
    }

    &__item {
      &._open {
        .main-menu {
          &__child-list {
            display: block;
          }

          &__link-arrow {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &__link {
      line-height: 150%;
      font-weight: 500;
    }

    &__child-list {
      padding: 0 10px;
      display: none;
    }

    &__child-link {
      width: 100%;
    }

    &__link-arrow {
      display: flex;
      align-items: center;
      margin-left: 5px;
      transition: {
        property: transform;
        duration: .2s;
      };
    }
  }
}

@include respond-down('small') {
  .main-menu {

    &__list {
      padding: 28px 0 35px;
    }

    &__link {
      font-size: 16px;
      padding: 12px 0 8px;
    }

    &__child-list {
      padding-bottom: 10px;
    }

    &__child-link {
      padding: 7px 0 8px;
    }
  }
}