.arrow-round {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  transition: var(--default-transition);
  border: none;

  &::before {
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
    border-radius: 100%;
    border: 1px solid var(--color-orange);
    opacity: 0.3;
  }

  &:hover {
    svg {
      path {
        stroke: var(--color-orange);
      }
    }
  }

  &_left {
    margin-right: 10px;
  }

  &_right {

  }
}