.advantages-slider{
  &__item{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__content{
    display: flex;
    flex-direction: column;
  }

  &__title{
    position: relative;
    font-style: normal;
    font-weight: bold;
    color: var(--primary-color);
    line-height: 130%;
    padding-bottom: 10px;

    &:before{
      content: '';
      position: absolute;
      background-image: url('../images/svg/quote.svg');
      background-repeat: no-repeat;
      width: 29px;
      height: 23px;
      top: 5px;
      left: -55px;
    }
  }

  &__description{
    font-style: normal;
    font-weight: normal;
    color: var(--gray-text);
    line-height: 160%;
  }
  &__person{
    display: flex;
  }
  &__person-image-wrap{
    border-radius: 100%;
    overflow: hidden;
  }
  &__person-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__person-name{
    font-style: normal;
    font-weight: 500;
    color: var(--primary-color);
    line-height: 150%;
  }
  &__person-info{
    display: flex;
    flex-direction: column;
  }
  &__person-function{
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    color: var(--gray-text);
  }
}

@include respond-up('large'){
  .advantages-slider{
    position: relative;
    background: #fff;
    box-shadow: 0px 15px 35px rgba(54, 44, 40, 0.1);
    border-radius: 10px;
    padding: 50px 54px 60px 42px;
    display: flex;
    justify-content: space-between;
    &__title{
      font-size: 22px;
      &:before{
        top: 5px;
        left: -55px;
      }
    }
    &__item{
      margin-left: 59px;
    }

    &__content{

    }

    &__description{
      max-width: 670px;
      font-size: 16px;
      padding-bottom: 40px;
    }

    &__control{
      position: absolute;
      top: 30px;
      right: 20px;
      z-index: 10;
    }

    &__person-image-wrap{
      margin-right: 20px;
      width: 58px;
      height: 58px;
    }

    &__person-name{
      font-size: 18px;
    }

    &__person-function{
     font-size: 15px;
    }
  }
}
@include respond('medium'){
  .advantages-slider{
    &__content-wrap{
      display: flex;
      flex-direction: column;
      padding-left: 58px;
      padding-right: 50px;
    }
  }
}

@include respond-down('medium'){
  .advantages-slider{
    .tns-outer{
      margin: 0 -#{$spacer-medium}px;
    }

    &__item{
        margin: 0 #{$spacer-medium}px;
        padding-bottom: 60px;
      display: flex;
    }

    &__content{
      padding: 50px 50px 64px 42px;
      margin-right: 20px;
      background: #fff;
      box-shadow: 0px 15px 35px rgba(54, 44, 40, 0.1);
      border-radius: 10px;
    }


    &__text-wrap{
    }

    &__title{
      font-size: 20px;
    }

    &__description{
      font-size: 16px;
      padding-bottom: 14px;
    }

    &__control{
      display: none;
    }

    &__person-image-wrap{
      margin-right: 20px;
      width: 58px;
      height: 58px;
    }

    &__person-name{
      font-size: 18px;
    }
    &__person-function{
      font-size: 15px;
    }
  }
}

@include respond-down('small'){
  .advantages-slider{
    .tns-outer{
      margin: 0 -#{$spacer-small}px;
    }

    &__item{
      margin: 0 #{$spacer-small}px;
      padding-bottom: 60px;
    }

    &__content{
      padding: 20px 20px 20px 29px;
      margin-right: 15px;
    }

    &__title{
      font-size: 18px;
      &:before{
        top: -34px;
        left: 0;
      }
    }

    &__content-wrap{
      padding-top: 37px;
    }

    &__person-image-wrap{
      margin-right: 14px;
    }
    &__person-name{
      font-size: 16px;
    }
    &__person-function{
      font-size: 13px;
    }
  }
}