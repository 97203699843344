.workers {

  &__name {
    font-size: 18px;
    line-height: 135%
  }

  &__list {
    display: grid;
  }

  &__main-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar {
    object-fit: cover;
    border-radius: 10px;
  }

  &__modal-window {
    transition: var(--default-transition);
    opacity: 0;
  }

  &__profession {
    font-size: 15px;
    line-height: 160%;
  }
}

.workers-main{
    .workers{
      &__list {
      display: flex;
      flex-wrap: nowrap;
      grid-gap: 0;
    }
  }
}

@include respond-up('large') {
  .workers {
    margin-bottom: 100px;
    &__label {
      margin-bottom: 30px;
    }

    &__title-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      position: relative;
    }

    &__title{

    }

    &__closer {
      display: none;
    }

    &__subtitle {
      max-width: 585px;
    }

    &__list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 84px;
    }

    &__items {
      cursor: pointer;
      position: relative;

      &:hover {
        .workers__modal-window {
          pointer-events: auto;
          opacity: 1;
        }
      }

      &:nth-child(4n+1), &:nth-child(4n+2) {
        .workers__modal-window {
          right: -380px;
          top: 50px;
        }

      }

      &:nth-child(4n+3), &:nth-child(4n+4) {
        .workers__modal-window {
          left: -380px;
          top: 40px;
        }
        .worker-description {
          &::before {
            left: 370px;
            border: 10px solid transparent;
            border-left: 10px solid white;
          }
        }
      }
    }
    &__photo-wrapper{
      width: 100%;
    }

    &__avatar {
      margin-bottom: 20px;
      width: 100%;
      height: 364px;
    }

    &__link {
      display: none;
    }

    &__modal-window {
      position: absolute;
      z-index: 10;
      pointer-events: none;
    }
    &__control{
      position: absolute;
      top: 60px;
    }
  }
}

@include respond('medium') {
  .workers {
    margin-bottom: 60px;

    &__label {
      margin-bottom: 25px;
    }

    &__title-wrapper {
      margin-bottom: 60px;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__name {
      white-space: nowrap;
      margin-bottom: 10px;
    }

    &__list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 30px;
    }

    &__avatar {
      margin-bottom: 25px;
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-down('medium') {
  .workers {
    &__label {

    }

    &__photo-wrapper{
      width: 100%;
    }

    &__link {
      z-index: 20;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &__name {
      border-bottom: 1px solid;
    }

    &__items {
      position: relative;
    }

    &__item-wrapper {
      position: relative;
    }

    &__modal-window {
      z-index: 100;
      transition: var(--default-transition);
      display: none;

      &._show {
        opacity: 1;
        position: fixed;
        left: 50%;
        top: 25%;
        transform: translateX(-50%);
        display: block;
      }
    }
    &__control{
      display: none;
    }
  }
  .workers-main{
    .tns-outer{
      margin: 0 -#{$spacer-medium}px;
    }
    .workers{
      &__list{
        margin-left: #{$spacer-medium}px;
      }
    }
  }
}

@include respond-down('small') {
  .workers {
    margin-bottom: 40px;
    &__label {
      margin-bottom: 25px;
    }

    &__title-wrapper {
      margin-bottom: 45px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__name {
      font-size: 15px;
      margin-bottom: 4px;
    }
    &__profession {
      font-size: 13px;
    }

    &__items {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__list {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 12px;
      grid-row-gap: 24px;
    }

    &__avatar {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .workers-main{
    .tns-outer{
      margin: 0 -#{$spacer-small}px;
    }

    .workers{
      &__list{
        margin-left: #{$spacer-small}px;
      }
    }
  }
}