.another-news {

  &__list {
    margin-top: 20px;
    border-top: 1px solid var(--color-light-gray-opacity);
  }

  &__item {
    padding: 20px 0 30px;
    border-bottom: 1px solid var(--color-light-gray-opacity);
  }

  &__heading {
    font-size: 17px;
    line-height: 135%;
    color: var(--primary-color);
    padding-top: 12px;
  }
}

@include respond-down('medium') {
  .another-news {

  }
}

@include respond-down('small') {
  .another-news {

  }
}