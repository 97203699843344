.header {
  &__inner {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  &__top {
    background-color: var(--primary-color);
    transition: {
      property: background-color;
      duration: .3s;
      timing-function: ease-in-out;
    };
  }

  &__bottom {
    transition: {
      property: background-color;
      duration: .3s;
      timing-function: ease-in-out;
    };
  }

  &__top-inner {
    height: var(--header-top-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    &_mobile {
    }

    &_desktop {
    }

    &_offer {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__bottom-inner {
  }

  &__info {
  }

  &__info-item {
  }

  &__info-icon {
  }

  &__info-text {
  }

  &__calls {
  }

  &__phone {
  }

  &__phone-icon {
  }

  &__phone-text {
  }

  &__callback {
  }

  &__logo {
    &_offer {
      display: none;
    }
  }

  &_transparent {
    position: absolute;
    top: 0;
    left: 0;

    .header {
      &__top {
        background-color: transparent;
      }

      &__top, &__bottom {
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background: var(--border-color);
          opacity: .3;
        }
      }

      &__logo {
        &_desktop {
          display: none;
        }

        &_offer {
          display: block;
        }
      }
    }
  }

  &._sticky {
    .header {

      &__top {
        background-color: var(--primary-color);
      }

      &__bottom {
        background-color: var(--background-color);
      }

      &__bottom-inner {
        .main-menu {
          &__link {
            color: var(--primary-color);;
          }
        }
      }

      &__logo {
        &_desktop {
          //display: flex;
          //align-items: center;
          //height: 100%;
          display: block;
        }

        &_offer {
          display: none;
        }
      }
    }
  }
}

@include respond-up('large') {
  .header {
    --header-top-height: 46px;
    --header-bottom-height: 74px;
    padding-bottom: calc(var(--header-top-height) + var(--header-bottom-height));

    &__inner {
      box-shadow: 0 4px 20px rgba(54, 44, 40, 0.1);
    }

    &__bottom {
      background-color: var(--background-color);
    }

    &__bottom-inner {
      height: var(--header-bottom-height);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info {
      height: 100%;
      display: flex;
    }

    &__info-item {
      height: 100%;
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 30px;
      }

      &:hover {
        .header {
          &__info-text {
            color: var(--color-orange-hover);
          }
        }
      }
    }

    &__info-icon {
      margin-right: 8px;
    }

    &__info-text {
      font-size: 13px;
      line-height: 155%;
      color: white;
      transition: {
        property: color;
        duration: .3s;
      };
    }

    &__phone-icon {
    }

    &__hamburger {
      display: none;
    }

    &__logo-wrapper {
      &_mobile {
        display: none;
      }
    }

    &__menu {
      height: 100%;
    }

    &_transparent {
      .header {
        &__top, &__bottom {
          background-color: transparent;
          position: relative;

          &:before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: var(--border-color);
            opacity: .3;
          }
        }

        &__bottom-inner {
          .main-menu {
            &__link {
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__calls {
      height: 100%;
      display: flex;
    }

    &__phone {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 19px;
    }

    &__phone-icon {
      display: none;
    }

    &__phone-text {
      font-size: 16px;
      line-height: 155%;
      color: white;
      transition: {
        property: color;
        duration: .3s;
      };

      &:hover {
        color: var(--color-orange-hover);
      }
    }

    &__callback {
      height: 100%;
      font-size: 15px;
      line-height: 155%;
      color: white;
      display: flex;
      align-items: center;
      padding-left: 19px;
      position: relative;
      transition: {
        property: color;
        duration: .3s;
      };

      &:hover {
        color: var(--color-orange-hover);
      }

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        left: 0;
        height: 19px;
        top: calc(50% - (19px / 2));
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

@include respond('medium') {
  .header {
    --header-top-height: 56px;
    padding-bottom: 56px;

    &__top-inner {
      height: var(--header-top-height);
    }

    &__hamburger {
      margin-right: 30px;
    }
  }
}

@include respond-down('medium') {
  .header {
    padding-bottom: var(--header-top-height);

    &__bottom {
      background-color: white;
      position: fixed;
      top: var(--header-top-height);
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
      display: none;

      &._open {
        display: block;
      }
    }

    &__hamburger {
      flex: 0 0 20px;
    }

    &__phone {
      justify-content: flex-end;
    }

    &__info {
      display: none;
    }


    &__logo-wrapper {
      &_mobile {
        flex: 1 1 auto;
      }

      &_desktop {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .header {
    --header-top-height: 48px;

    &__phone-text, &__callback {
      display: none;
    }

    &__logo-wrapper {
      &_mobile {
        justify-content: center;
      }
    }
  }
}