.advantages-main{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--border-color);
    bottom: 0;
  }

  &__wrap{
    display: flex;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item{
    background-color: #fff;
    box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
    border-radius: 10px;
    &:nth-child(2){
      background-color: var(--color-brown-another);
    }
    &:nth-child(4){
      background-color: var(--color-orange-light);
    }
    &:last-child{
      margin-right: 0;
    }
  }

  &__text{
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    color: var(--gray-text);
  }
}

@include respond-up('large'){
  .advantages-main{
    &__wrap{
      margin-bottom:100px;
      overflow: hidden;
    }

    &__item {
      margin-right: 24px;
      transform: translateY(100%);
      opacity: 0;
      &:nth-child(1){
        transition: 0.6s all
      }
      &:nth-child(2){
        transition: 0.8s all
      }
      &:nth-child(3){
        transition: 1s all
      }
      &:nth-child(4){
        transition: 1.2s all
      }

      &.animate{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

@include respond-up('medium'){
  .advantages-main{
    &__wrap{
      padding-bottom: 40px;
    }
    &__item {
      padding: 25px 36px 30px 33px;
      min-width: 282px;
    }
    &__text{
     font-size: 16px;
      min-width: 213px;
    }
  }
}

@include respond-down('medium'){
  .advantages-main{
    &__wrap{
      overflow-x: scroll;
      margin: 0 -#{$spacer-medium}px 60px;
    }
    &__item {
      margin-right: 20px;
      &:first-child{
        margin-left: #{$spacer-medium}px;
      }
      &:last-child{
        margin-right: #{$spacer-medium}px;
      }
    }
  }
}

@include respond-down('small'){
  .advantages-main{
    &__wrap{
      padding-bottom: 30px;
      margin: 0 -#{$spacer-small}px 40px;
    }

    &__item {
      padding: 15px 20px;
      margin-right: 11px;
      min-width: 245px;

      &:first-child{
        margin-left: #{$spacer-small}px;
      }
      &:last-child{
        margin-right: #{$spacer-small}px;
      }

    }

    &__text{
      font-size: 15px;
      min-width: 205px;
    }
  }
}