.service-question {
  &__list {

  }

  &__item {
    display: flex;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--border-color-gray);
    }
  }

  &__icon {

  }

  &__title {
    color: var(--primary-color);
  }

  &__text {
    color: var(--primary-color);
  }

  &__content-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .service-question {
    &__title {
      margin-right: 126px;
    }

    &__text {
      flex: 0 0 486px;
    }
  }
}

@include respond-up('medium') {
  .service-question {
    &__title {
      font-size: 20px;
      line-height: 27/20*1em;
      padding-left: 16px;
      flex: 0 0 242px;
    }

    &__text {
      font-size: 16px;
      line-height: 26/16*1em;
    }

    &__item {
      padding: 20px 0 46px;
    }
  }
}

@include respond('medium') {
  .service-question {
    &__title {
      margin-right: 72px;
    }
  }
}

@include respond-down('medium') {

}

@include respond-down('small') {
  .service-question {
    &__title {
      font-size: 18px;
      line-height: 24/18*1em;
      padding-bottom: 14px;
    }

    &__text {
      font-size: 15px;
      line-height: 24/15*1em;
    }

    &__content-wrapper {
      flex-direction: column;
      padding-left: 16px;
    }

    &__item {
      padding: 20px 0 30px;
    }
  }
}