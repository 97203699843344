.floating-field {
  position: relative;

  label {
    position: absolute;
    height: var(--default-input-height);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--color-gray);
    font-size: 16px;
    transition: all 0.2s;
    margin: 0;
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label{
    font-size: 12px;
    color: var(--color-gray);
    top: -20px;
  }

  &>input[type="text"], &>input[type="password"], &>input[type="search"] {
    padding-top: 11px;
    padding-bottom: 7px;
  }

  &>textarea {
    padding-top: 20px;
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
    }
  }
}