.publications-items {

  &__list {
    display: flex;
  }

  &__item-wrapper {
    display: flex;
    flex: 0 0 auto;
    position: relative;
  }

  &__item {
    background-color: #fff;
    width: 100%;
    transition: {
      property: box-shadow;
      duration: .4s;
    };

  }

  &__picture {
    position: relative;
    display: flex;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__created {
    position: absolute;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    line-height: 135%;
    color: var(--primary-color);
  }

  &__arrow {
    margin-top: auto;
  }
}

@include respond-up('large') {
  .publications-items {
    &__list {
      margin: 0 -14px;
    }

    &__item{
      &:hover {
        box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
      }
    }

    &__item-wrapper {
      padding: 0 12px;
      width: 33%;
    }

    &__title {
      margin-bottom: 52px;
    }
    &__created {
      top: 25px;
      right: 41px;
    }
  }
}

@include respond-up('medium') {
  .publications-items {
    &__item {
      border-radius: 10px;
    }

    &__link {
      padding: 25px;
    }


    &__picture {
      border-radius: 10px;
      margin-bottom: 25px;
      width: 115px;
      height: 115px;
    }

    &__title {
      font-size: 20px;
    }
  }
}

@include respond('medium') {
  .publications-items {
    margin: 0 -#{$spacer-medium}px;
    padding-bottom: 87px;

    &__list-wrap {
      padding: 0 #{$spacer-medium}px;
    }

    &__list {
      margin: 0 -12px;
    }

    &__item-wrapper {
      padding: 0 10px;
      width: 314px;
    }

    &__title {
      margin-bottom: 35px;
    }
  }
}

@include respond-down('medium') {
  .publications-items {
    padding-bottom: 71px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__created {
      top: 20px;
      right: 32px;
    }

    &__item{
      box-shadow: 0 15px 35px rgba(54, 44, 40, 0.1);
    }
  }
}

@include respond-down('small') {
  .publications-items {
    margin: 0 -#{$spacer-small}px;

    &__list-wrap {
      padding: 0 #{$spacer-small}px;
    }

    &__list {
      margin: 0 -7px;
    }

    &__item-wrapper {
      padding: 0 7px;
      width: 304px;
    }

    &__item {
      border-radius: 8px;
    }

    &__link {
      padding: 20px;
    }

    &__picture {
      border-radius: 8px;
      margin-bottom: 18px;
      width: 104px;
      height: 104px;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}